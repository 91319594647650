.header-panel {
    text-align: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    // sticky header
    position: fixed;
    width: 100%;
    z-index: 25000;
    top: 0;
    background-color: $white;
}

.page-content {
    // place for sticky header
    padding: 60px 0 0;
}

.header-side {
    width: 100px;
    text-align: left;
}

.header-side-right {
    text-align: right;
}

.header-burger {
    display: inline-flex;
    align-items: center;
    padding: 10px 20px;
    width: 60px;
    height: 60px;
}

.header-logo {
    display: inline-block;
    padding: 5px;
    height: 60px;

    @include media('>=desktop') {
        padding: 5px 10px 5px 40px;
    }
}

.header-locales {
    display: inline-flex;
    align-items: center;
    padding: 10px 20px;
    height: 60px;

    @include media('>=desktop') {
        padding: 10px 40px 10px 0;
    }
}

.header-locale-link {
    position: relative;
    font: 14px / 18px $ReaderMediumPro;

    &.active {
        &::after {
            content: '';
            left: 0;
            position: absolute;
            bottom: 0;
            display: block;
            background-color: $black1;
            width: 100%;
            height: 1px;
        }
    }
}

.header-locales-separator {
    display: inline-block;
    font: 14px / 18px $ReaderMediumPro;
    padding: 0 5px;
}

.header-desktop-menu {
    display: flex;
    height: 60px;
    align-items: center;
}

// Desktop header navigation
.header-menu-item {
    margin: 0 20px;

    &:first-child {
        margin: 0 20px 0 0;
    }

    &:last-child {
        margin: 0 0 0 20px;
    }
}

.header-dropdown-item {
    &:hover {
        .header-menu-link::after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 2px;
            background-color: $blue4;
        }
    }
}

.header-menu-link {
    display: inline-block;
    font: 14px / 18px $ReaderMediumPro;
    padding: 21px 0;
    cursor: pointer;
    position: relative;

    &:hover {
        font-weight: bold;
    }

    &.donation-link {
        padding: 5px 33px;
        border-radius: 15px;
        border: 1px solid $black1;
    }
}

// Header sub-menu
.header-sub-menu {
    display: none;

    @include media('>=desktop') {
        justify-content: center;
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 10000;
        width: 100%;
        background-color: $white;
        border: solid 1px $grey1;
        padding: 50px;
    }

    &.visible {
        display: flex;
    }

    &.open {
        display: block;
    }
}

.header-sub-menu-item {
    position: relative;
}

.header-sub-menu-bg {
    @include absolute-center;

    width: 100%;
    height: 100%;
}

.header-sub-menu-bg-color {
    text-align: center;
    height: 100%;

    &.orange-tile {
        background-color: $orange-tile;
    }

    &.green-tile {
        background-color: $green-tile;
    }

    &.yellow-tile {
        background-color: $yellow-tile;
    }

    @include media('>=desktop') {
        margin: 0 20px;
    }
}

.header-sub-menu-img {
    max-height: 100%;
}

.header-sub-menu-link {
    position: relative;
    display: block;
    text-align: center;
    margin: 20px 0 0;
    padding: 15px 10px 20px;

    @include media('>=desktop') {
        max-width: 240px;
        margin: 0 20px;
    }

    .navigation-desktop-fr & {
        @include media('>=desktop') {
            max-width: 270px;
        }
    }
}

.header-sub-menu-tile-svg {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
}

.header-sub-menu-tile-title {
    font: 28px / 36px $ReaderMediumPro;
    margin: 0 0 10px;
}

.header-sub-menu-tile-btn {
    width: 128px;
    background-color: $white;
    margin: 0 auto;
    border-radius: 15px;
    font: 14px / 30px $ReaderMediumPro;

    &:hover {
        font-weight: bold;
    }
}

// Mobile header navigation
.navigation-mobile {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    left: -100%;
    bottom: 0;
    border: 0;
    background-color: $transparent-black1;
    // turn off keyboard accessibility
    visibility: hidden;
}

.header-mobile-menu {
    position: absolute;
    top: 0;
    left: -100%;
    width: 290px;
    min-height: 100%;
    text-align: left;
    padding: 0 0 100px;
    background: $white;
    transition: left 0.5s cubic-bezier(0, 1, 0.5, 1);

    @include media('>=tablet') {
        width: 300px;
    }

    .navigation-mobile-fr & {
        width: 310px;

        @include media('>=tablet') {
            width: 320px;
        }
    }
}

.navigation-mobile.show {
    left: 0;
    overflow: scroll;
    z-index: 20001;
    // turn on keyboard accessibility
    visibility: visible;

    .header-mobile-menu {
        left: 0;
    }
}

.mobile-menu-close {
    width: 60px;
    height: 59px;
    text-align: center;
    padding: 20px;
}

.mobile-header-menu-item {
    margin: 40px 30px 0 20px;

    @include media('>=tablet') {
        margin: 40px 30px 0;
    }
}

.mobile-header-menu-link {
    display: flex;
    justify-content: space-between;
    // line-height: 27px is important for ouline
    font: 20px / 27px $ReaderMediumPro;
    border-bottom: 1px solid $black1;

    &.active {
        .svgchevron {
            transform: rotate(-90deg);
        }
    }
}

.mobile-header-menu-link-text {
    padding: 0 0 6px;
}
