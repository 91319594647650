// Common program pages styles
.programs-detail_pages-block {
    .text_image-wrapper {
        position: relative; // for outer snakes
        padding: 0 30px 100px;
        overflow: visible; // for outer snakes

        @include media('>=tablet') {
            max-width: 768px;
            margin: 0 auto;
            padding: 0 100px 100px;
        }

        @include media('>=desktop') {
            max-width: 1440px;
        }

        &:nth-child(even) {
            .text_image-title {
                @include media('>=desktop') {
                    text-align: right;
                }
            }

            .gl_lines-container::before {
                @include media('>=desktop') {
                    left: auto;
                    right: -26px;
                }
            }

            .text_block {
                @include media('>=desktop') {
                    text-align: right;
                }
            }
        }
    }

    .text_image-col {
        &.image_block {
            background-color: $grey3;

            @include media('<=tablet') {
                order: -1;
            }
        }

        &.text_block {
            background-color: $grey3;

            @include media('>=tablet') {
                padding: 60px;
            }

            @include media('>=desktop') {
                padding: 30px 80px;
            }

            @include media('>=desktopLarge') {
                padding: 30px 130px;
            }
        }
    }
}

// outer snakes on detail_pages-block
.programs-detail1-snake-1 {
    @include media('<tablet') {
        top: -100px;
        left: 0;
        transform: translateX(-135px); // no calc for translateX...
        width: 271px;
        height: 445px;

        .svgsnake {
            width: 406px;
            height: 445px;
        }
    }

    @include media('>=tablet', '<desktop') {
        top: -109px;
        left: 0;
        transform: translateX(-164px); // no calc for translateX...
        width: 364px;
        height: 580px;

        .svgsnake {
            width: 528px;
            height: 580px;
        }
    }

    @include media('>=desktop') {
        top: -74px;
        left: 0;
        transform: translateX(-158px); // 528px - 370px, no calc for translateX...
        width: 370px;
        height: 580px;

        .svgsnake {
            width: 528px;
            height: 580px;
        }
    }
}

.programs-detail1-snake-2 {
    @include media('<tablet') {
        top: -248px;
        right: 0;
        width: 281px;
        height: 513px;

        .svgsnake {
            width: 430px;
            height: 513px;
        }
    }

    @include media('>=tablet', '<desktop') {
        top: -262px;
        right: 0;
        width: 404px;
        height: 667px;

        .svgsnake {
            width: 558px;
            height: 667px;
        }
    }

    @include media('>=desktop') {
        top: -238px;
        right: 0;
        width: 411px;
        height: 668px;

        .svgsnake {
            width: 559px;
            height: 668px;
        }
    }
}

.programs-detail1-snake-3 {
    @include media('<tablet') {
        top: -302px;
        left: 0;
        transform: translateX(-78px); // 367 - 289, no calc for translateX...
        width: auto;
        height: 406px;

        .svgsnake {
            width: 367px;
            height: 407px;
        }
    }

    @include media('>=tablet', '<desktop') {
        top: -401px;
        left: 0;
        transform: translateX(-44px); // no calc for translateX...
        width: auto;
        height: 529px;

        .svgsnake {
            width: 477px;
            height: 529px;
        }
    }

    @include media('>=desktop') {
        top: -400px;
        left: 25px;
        width: auto;
        height: 529px;

        .svgsnake {
            width: 477px;
            height: 529px;
        }
    }
}

// Program detail pages styles
.programdetail-page {
    overflow: hidden;
}

.topsection-programdetail {
    margin: 40px 30px 60px;

    @include media('>=tablet') {
        max-width: 448px;
    }

    @include media('>=desktop') {
        margin: 75px auto 60px;
    }

    .topsection-wave {
        margin: 20px 0 30px;

        @include media('>=desktop') {
            margin: 25px 0 30px;
        }
    }

    .topsection-description {
        font: 12px / 20px $ReaderRegularPro;
        letter-spacing: 1px;

        @include media('>=tablet') {
            max-width: 448px;
            margin: 0 auto;
        }

        @include media('>=desktop') {
            max-width: none;
        }
    }
}

.programdetail-header_image {
    margin: 0 30px;

    @include media('>=tablet') {
        max-width: 568px;
        margin: 0 auto;
    }

    @include media('>=desktop') {
        max-width: none;
        margin: 0 40px 100px;
    }

    @include media('>=desktopLarge') {
        max-width: 1360px;
        margin: 0 auto 100px;
    }
}

.programdetail-top-snake {
    @include media('<tablet') {
        bottom: -34px;
        right: 0;
        width: 151px;
        height: 206px;

        .svgsnake {
            width: 475px;
            height: 206px;
        }
    }

    @include media('>=tablet', '<desktop') {
        bottom: -46px;
        right: 0;
        width: 224px;
        height: 289px;

        .svgsnake {
            width: 668px;
            height: 289px;
        }
    }

    @include media('>=desktop', '<desktopLarge') {
        bottom: -55px;
        right: 0;
        width: 300px;
        height: 289px;

        .svgsnake {
            width: 668px;
            height: 289px;
        }
    }

    @include media('>=desktopLarge') {
        bottom: -22px;
        right: calc(50% - 720px);
        width: 370px;
        height: 289px;

        .svgsnake {
            width: 668px;
            height: 289px;
        }
    }

    &.orange-snake {
        .svgsnake .st0 {
            fill: $orange-tile;
        }
    }

    &.green-snake {
        .svgsnake .st0 {
            fill: $green-tile;
        }
    }

    &.yellow-snake {
        .svgsnake .st0 {
            fill: $yellow-tile;
        }
    }
}

.programdetail-subblocks {
    padding: 60px 0 70px;
    overflow: hidden; // for outer snakes

    @include media('>=tablet') {
        padding: 60px 0;
        overflow: visible; // for outer snakes
    }

    @include media('>=desktop') {
        display: flex;
        flex-flow: row wrap;
        padding: 0 20px 60px;
    }

    @include media('>=desktopLarge') {
        max-width: 1400px;
        margin: 0 auto;
        padding: 0 0 60px;
    }
}

.programdetail-subblock_wrap {
    overflow: visible; // for outer snakes

    @include media('>=desktop') {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0 20px 40px;
    }
}

.programdetail-subblock {
    display: flex;
    flex-flow: column nowrap;
    background-color: $grey3;
    padding: 40px;
    margin: 0 30px 40px;

    @include media('>=tablet') {
        max-width: 568px;
        margin: 0 auto 40px;
        padding: 60px;
    }

    @include media('>=desktop') {
        margin: 0;
        max-width: none;
        height: 100%;
    }

    @include media('>=desktopLarge') {
        padding: 60px 100px;
    }

    h3 {
        font-weight: bold;
        margin: 0 0 6px;
    }

    p {
        margin: 0 0 20px;
    }

    ul {
        li {
            text-indent: 4px;

            &::before {
                content: ".";
                display: inline-block;
                font-weight: bold;
                position: relative;
                left: -8px;
                bottom: 3px;
            }
        }
    }

    ol {
        padding: 0;
        margin: 0;

        li {
            list-style-type: none;
        }
    }
}

.programdetail-subblock_icon {
    order: -1;
    margin: 0 0 30px;
}

.programdetail-subblock_title {
    font: 28px / 36px $ReaderMediumPro;
    margin: 0 0 24px;
}

.programdetail-subblock_desc {
    font: 12px / 20px $ReaderRegularPro;
    letter-spacing: 1px;
}

.programdetail-subblock_snakes {
    .orange-snake {
        .svgsnake .st0 {
            fill: $orange-tile;
        }
    }

    .green-snake {
        .svgsnake .st0 {
            fill: $green-tile;
        }
    }

    .yellow-snake {
        .svgsnake .st0 {
            fill: $yellow-tile;
        }
    }
}

.programdetail-block1-snake-1 {
    @include media('<tablet') {
        top: 127px;
        left: 0;
        transform: translateX(-218px); // no calc for translateX...
        width: 121px;
        height: 377px;
        overflow: visible;

        .svgsnake {
            width: 339px;
            height: 377px;
        }
    }

    @include media('>=tablet', '<desktop') {
        top: -232px;
        left: calc(50% - 284px - 100px);
        transform: translateX(-336px); // no calc for translateX...
        width: 140px;
        height: 529px;
        overflow: visible;

        .svgsnake {
            width: 476px;
            height: 529px;
        }
    }

    @include media('>=desktop') {
        top: -300px;
        left: -320px;
        width: 167px;
        height: 529px;
        overflow: visible;

        .svgsnake {
            width: 476px;
            height: 529px;
        }
    }
}

.programdetail-block1-snake-2 {
    @include media('<tablet') {
        top: -218px;
        right: 0;
        width: 350px;
        height: 475px;
        overflow: visible;

        .svgsnake {
            width: 398px;
            height: 475px;
        }
    }

    @include media('>=tablet', '<desktop') {
        top: -150px;
        right: calc(50% - 284px - 100px);
        width: 504px;
        height: 668px;
        overflow: visible;

        .svgsnake {
            width: 559px;
            height: 668px;
        }
    }

    @include media('>=desktop') {
        display: none;
    }
}

.programdetail-block2-snake-1 {
    display: none;

    @include media('>=desktop') {
        display: block;
        top: -175px;
        right: -30px;
        width: 520px;
        height: 668px;
        overflow: visible;

        .svgsnake {
            width: 559px;
            height: 668px;
        }
    }
}

.programdetail-block3-snake-1 {
    @include media('<tablet') {
        top: -223px;
        left: 0;
        transform: translateX(-56px); // no calc for translateX...
        width: 283px;
        height: 376px;
        overflow: visible;

        .svgsnake {
            width: 339px;
            height: 376px;
        }
    }

    @include media('>=tablet', '<desktop') {
        top: -344px;
        left: calc(50% - 284px - 100px);
        transform: translateX(-26px); // no calc for translateX...
        width: 450px;
        height: 528px;
        overflow: visible;

        .svgsnake {
            width: 476px;
            height: 529px;
        }
    }

    @include media('>=desktop') {
        top: -200px;
        left: -80px;
        width: 450px;
        height: 528px;
        overflow: visible;

        .svgsnake {
            width: 476px;
            height: 529px;
        }
    }
}

.programdetail-block4-snake-1 {
    @include media('<tablet') {
        bottom: -155px;
        left: 0;
        transform: translateX(-110px); // no calc for translateX...
        width: 288px;
        height: 475px;
        overflow: visible;

        .svgsnake {
            width: 398px;
            height: 475px;
        }
    }

    @include media('>=tablet', '<desktop') {
        bottom: -180px;
        left: calc(50% - 284px + 35px);
        width: 559px;
        height: 668px;
        overflow: visible;

        .svgsnake {
            width: 559px;
            height: 668px;
        }
    }

    @include media('>=desktop') {
        bottom: -80px;
        left: 50px;
        width: 559px;
        height: 668px;
        overflow: visible;

        .svgsnake {
            width: 559px;
            height: 668px;
        }
    }
}
