// Newsletter form
.newsletter-title {
    width: 100%;  // fix for legend in FF browser
    font: 20px / 26px $ReaderMediumPro;
    margin: 0 0 20px;
    text-align: center;
}

.newsletter-desc {
    font: 12px / 20px $ReaderRegularPro;
    letter-spacing: 1px;
    margin: 0 0 30px;
    text-align: center;
}

.newsletter-group {
    position: relative;
    width: 100%;

    @include media('>=tablet') {
        margin: 0 0 20px;
    }
}

.newsletter-email {
    @include media('>=tablet') {
        width: calc(100% - 100px);
    }
}

.newsletter-submit {
    margin: 0 0 40px;

    @include media('>=tablet') {
        width: 136px;
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
    }
}

.newsletter-consent {
    .gl_error-message {
        padding: 0;

        @include media('>=tablet') {
            padding: 0 0 0 30px;
        }
    }
}

.newsletter-form {
    .input-email {
        @include media('>=tablet') {
            padding: 0 60px 0 20px;
        }
    }

    .newsletter-email-success-container {
        @include media('>=tablet') {
            right: 30px;
        }
    }
}

.newsletter-success_message {
    font-size: 16px;
}

// hero banner
.hero_banner-wrapper {
    position: relative;
}

.hero_banner-img {
    margin: 0 auto;
}

.hero_banner-text_wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    padding: 70px 30px 20px;

    @include media('>=tablet') {
        padding: 140px 40px 40px;
    }

    @include media('>=desktop') {
        padding: 70px 40px 40px;
    }

    @include media('>=desktopLarge') {
        padding: 140px 40px 40px;
    }
}

.hero_banner-title {
    @include absolute-center;

    width: 100%;
    padding: 0 30px;
}

.hero_banner-h1 {
    font: 40px / 46px $ReaderMediumPro;
    color: $white;
    margin: 0 0 20px;

    @include media('>=tablet') {
        max-width: 440px;
        margin: 0 auto 20px;
    }

    @include media('>=desktop') {
        max-width: none;
    }

    @include media('>=desktopLarge') {
        font-size: 50px;
        line-height: 70px;
    }
}

.hero_banner-text {
    display: inline-flex;
    flex-flow: column nowrap;
    justify-content: flex-end;
    text-align: left;
    color: $white;
    margin: 0 0 30px;
    font-size: 18px;
    line-height: 22px;

    @include media('>=tablet') {
        font-size: 28px;
        line-height: 34px;
    }
}

.hero_banner-subtext {
    font: 20px / 22px $ReaderMediumPro;

    @include media('>=tablet') {
        font-size: 32px;
        line-height: 34px;
    }

    @include media('>=desktop') {
        font-size: 40px;
        line-height: 46px;
    }
}

// top section (e.g.: root news page or crewmember page
.topsection-wrapper {
    text-align: center;
    margin: 40px 30px;

    @include media('>=tablet') {
        max-width: 568px;
        margin: 40px auto 60px;
    }

    @include media('>=desktop') {
        max-width: 700px;
        margin: 75px auto;
    }
}

.topsection-title {
    margin: 0;
}

.topsection-wave {
    margin: 20px 0 25px;
    height: 15px;

    @include media('>=tablet') {
        margin: 20px 0 30px;
    }
}

.topsection-description {
    font: 28px / 36px $ReaderMediumPro;
}

// Text-image component template
.text_image-wrapper {
    display: flex;
    flex-flow: column nowrap;
    overflow: hidden;

    @include media('>=desktop') {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: stretch;
        max-width: 1440px;
        margin: 0 auto;
    }

    &.text_image-reverse-mobile {
        @include media('<desktop') {
            flex-direction: column-reverse;
        }
    }

    &.text_image-with-white-border {
        @include media('>=desktop') {
            border: 40px solid $white;
        }
    }

    &.orange-theme {
        background-color: $orange-tile;
    }

    &.green-theme {
        background-color: $green-tile;
    }

    &.yellow-theme {
        background-color: $yellow-tile;
    }
}

.text_image-col {
    @include media('>=desktop') {
        flex: 0 0 50%;
        max-width: 50%;
    }

    &.image_block {
        @include media('>=desktop') {
            display: flex;
            flex-flow: column;
            justify-content: center;
        }
    }

    &.text_block {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        padding: 60px 40px;

        @include media('>=tablet') {
            padding: 100px;
        }

        @include media('>=desktop') {
            padding: 30px 60px;
        }

        @include media('>=desktopLarge') {
            padding: 60px 140px;
        }
    }

    .text_image-with-big-frame &.text_block {
        @include media('>=desktop') {
            padding: 20px 108px;
        }
    }
}

.text_block {
    ul {
        margin-top: 12px;
    }

    li {
        text-indent: 4px;

        &::before {
            content: ".";
            display: inline-block;
            font-weight: bold;
            position: relative;
            left: -3px;
            top: -2px;
        }
    }
}

.text_image-img {
    margin: 0 auto;

    @include media('>=desktop') {
        margin: 0;
    }
}

.text_image-title_wrap {
    margin: 0 0 20px;
}

.text_image-title.gl_lines-text {
    font: 28px / 36px $ReaderMediumPro;
}

.text_image-text {
    .text_image-link {
        width: auto;
    }
}

.text_image-desc {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
    margin: 0 0 20px;

    .text_image-with-big-desc & {
        font: 20px / 26px $ReaderMediumPro;
        letter-spacing: normal;
    }
}

// initiatives_carousel template
.initiatives_carousel-wrapper {
    position: relative;
    overflow: hidden;
    padding: 100px 0;
}

.initiatives_carousel-content {
    position: relative;

    .initiatives_carousel-arrows {
        margin: -25px 30px 0 0;

        @include media('>=tablet') {
            margin: -25px 100px 0 0;
        }

        @include media('>=desktop') {
            margin: 0;
        }

        .slick-arrow {
            @include media('>=desktop') {
                @include absolute-vertical-center;

                margin: 0;
                border: 1px solid $blue4;
            }
        }

        .slick-disabled {
            @include media('>=desktop') {
                display: none !important;
            }
        }

        .slick-prev {
            @include media('>=desktop') {
                left: calc(50% - 500px);
            }

            @include media('>=desktopLarge') {
                left: calc(50% - 610px);
            }
        }

        .slick-next {
            @include media('>=desktop') {
                right: calc(50% - 500px);
            }

            @include media('>=desktopLarge') {
                right: calc(50% - 610px);
            }
        }
    }
}

// helper container to position outside arrows
.initiatives_carousel-container {
    position: relative;
}

.initiatives_carousel-bg {
    @include absolute-horizont-center;

    top: 0;
    width: 100%;

    .initiatives_carousel-img {
        margin: 0 auto;
    }
}

.initiatives_carousel-title {
    font: 28px / 36px $ReaderMediumPro;
    text-align: center;
    margin: 0 0 30px;
}

.initiatives_carousel:not(.slick-initialized) {
    padding: 0 30px;

    @include media('>=tablet') {
        padding: 0 100px;
    }

    @include media('>=desktop') {
        padding: 0 15px;
        max-width: 930px;
        margin: 0 auto;
    }

    @include media('>=desktopLarge') {
        max-width: 1150px;
    }
}

.initiatives_carousel.slick-initialized {
    margin: 0 0 0 30px;

    @include media('>=tablet') {
        margin: 0 0 0 100px;
    }

    @include media('>=desktop') {
        margin: 0;
    }

    .slick-list {
        @include media('>=desktop') {
            padding: 0 0 0 calc(50% - 450px);
        }

        @include media('>=desktopLarge') {
            padding: 0 0 0 calc(50% - 560px);
        }
    }

    .slick-slide {
        width: calc(100vw - 60px); // variableWidth = true
        margin: 0 8px;

        @include media('>=tablet') {
            width: calc(100vw - 200px);
            margin: 0 15px;
        }

        @include media('>=desktop') {
            display: flex;
            width: 900px;
        }

        @include media('>=desktopLarge') {
            width: 1120px;
        }
    }

    .slick-dots {
        justify-content: flex-start;
        margin: 70px 0 0;

        @include media('>=desktop') {
            justify-content: center;
            margin: 30px 0 0;
        }
    }

    .slick-prev {
        left: -55px;
    }

    .slick-next {
        right: -55px;
    }
}

.initiatives_carousel-slide {
    background-color: $white;

    @include media('>=desktop') {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
    }
}

.initiatives_carousel-slide_col {
    @include media('>=desktop') {
        flex: 0 0 50%;
        max-width: 50%;
    }
}

.initiatives_carousel-slide_content {
    padding: 60px 40px;

    @include media('>=tablet') {
        padding: 60px;
    }

    @include media('>=desktop') {
        padding: 60px 80px; // not following Zeplin, block is vertically centered
    }

    @include media('>=desktopLarge') {
        padding: 60px 100px; // not following Zeplin, block is vertically centered
    }
}

.initiatives_carousel_subtitle-wrap {
    margin: 0 0 10px;

    @include media('>=tablet') {
        margin: 0 0 28px;
    }

    @include media('>=desktop') {
        margin: 0 0 20px;
    }
}

.initiatives_carousel-subtitle {
    font: 28px / 36px $ReaderMediumPro;
}

.initiatives_carousel-desc {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1px;
}

.initiatives_carousel-link.gl_btn {
    width: auto;
    margin: 20px 0 0;
}

// text-only block
.text-only-wrapper {
    background-position: center center;
    background-size: cover;
}

.text-only-title {
    font: 28px / 36px $ReaderMediumPro;
    letter-spacing: 0;
    margin: 0;
}

.text-only-description {
    font: 14px / 20px $ReaderRegularPro;
    letter-spacing: 1px;
    margin: 0;
}

// Video component
.video-article-description {
    font: 12px / 20px $ReaderRegularPro;
    letter-spacing: 1px;
    text-align: center;
    margin: 60px 0 0;
}

// Carousel component
.vf-carousel-container {
    position: relative; // for custom slick controls
}

.vf-carousel-controls {
    position: absolute;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 100%;
    top: 10px;
    left: 0;

    @include media('>=desktop') {
        width: 50%;
        top: auto;
        bottom: 20px;
    }
}

.vf-carousel-dots {
    display: flex;
    margin: 0 0 0 10px;

    @include media('>=desktop') {
        margin: 0 0 0 30px;
    }

    .slick-dots {
        margin: 0;
    }
}

.vf-carousel-arrows {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    height: 40px;

    @include media('>=desktop') {
        margin: 0 10px 0 0;
    }

    .slick-arrow {
        margin: 0 10px;
        overflow: hidden;

        &::before {
            margin: 15px 10px;
        }
    }
}

.vf-carousel.slick-initialized {
    .slick-slide {
        @include media('>=desktop') {
            display: flex !important;
        }
    }
}

.vf-carousel-slide {
    @include media('>=desktop') {
        display: flex;
        flex-flow: row nowrap;
        align-items: stretch;
    }
}

.vf-carousel-text {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
}
