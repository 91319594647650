// Donation form: step 3 - adyen form
.donation-adyen_form {
    .adyen-checkout__card__holderName {
        margin: 0;
        padding: 0 0 28px;
    }

    .adyen-checkout__card__form {
        .adyen-checkout__field--cardNumber {
            margin: 0;
        }
    }

    .adyen-checkout__card__exp-cvc {
        display: flex;
        flex-flow: column nowrap;

        @include media('>=tablet') {
            flex-flow: row nowrap;
            margin: 0 -5px;
        }

        .adyen-checkout__field {
            width: 100%;
            margin: 0;

            @include media('>=tablet') {
                margin: 0 5px;
            }
        }
    }

    .adyen-checkout__field {
        padding: 0 0 28px;

        &.adyen-checkout__field--error {
            padding: 0 0 8px;
        }
    }

    .adyen-checkout__label {
        position: relative;
        margin: -12px 0 0;

        &.adyen-checkout__label--focused {
            .adyen-checkout__label__text {
                color: $black1;
            }
        }
    }

    .adyen-checkout__label__text {
        position: relative;
        display: inline-block;
        padding: 0 8px 0 2px;
        margin: 0 0 -12px 20px;
        z-index: 1;
        background: $white;
        font: 12px / 14px $ReaderMediumPro;

        &::before {
            position: absolute;
            right: 0;
            content: '*';
        }

        &.adyen-checkout__label__text--error {
            color: $black1;
        }
    }

    .adyen-checkout__input {
        border: 1px solid $black1;
        font: 12px / 14px $ReaderRegularPro;
        letter-spacing: 1px;
        height: 40px;
        padding: 0 40px 0 20px;
        color: $grey2;
        width: 100%;
        position: relative;
        border-radius: 20px;

        &:focus,
        &:hover,
        &.adyen-checkout__input--focus,
        &.adyen-checkout__input--focus:hover,
        &:active {
            box-shadow: none !important;
        }

        &:hover,
        &.adyen-checkout__input--error,
        &.adyen-checkout__input--error:hover,
        &.adyen-checkout__input--invalid,
        &.adyen-checkout__input--invalid:hover {
            border-color: $black1;
        }

        &.adyen-checkout__input--error:focus,
        &.adyen-checkout__input--invalid:focus {
            border-color: $blue4;
        }
    }

    .adyen-checkout__error-text {
        font: 12px / 20px $ReaderRegularPro;
        letter-spacing: 1px;
        color: $blue4;
        margin: 0;
    }

    .adyen-checkout-input__inline-validation--invalid {
        display: none;
    }

    .adyen-checkout-input__inline-validation--valid {
        right: 10px;

        path {
            fill: $black1;
        }
    }
}
