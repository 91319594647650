.ui-front {
    z-index: 100;
}

.ui-helper-hidden-accessible {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

// =================================
//    Radio Button
// =================================
.ui-checkboxradio-radio-label {
    position: relative;
    display: inline-block;
    padding: 0 0 0 25px;
    cursor: pointer;
    font: 12px / 20px $ReaderRegularPro;
    letter-spacing: 1px;
    color: $black1;

    .ui-icon-background {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        overflow: visible;
        border: 1px solid $black1;
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        background-color: $white;
    }

    .ui-checkboxradio-icon-space {
        display: none;
    }

    &.ui-state-active {
        .ui-icon-background {
            &::before {
                content: '';
                width: 10px;
                height: 10px;
                position: absolute;
                top: 4px;
                left: 4px;
                background-color: $black1;
                border-radius: 50%;
            }
        }
    }

    // EADA-25 - Lack of focus indicators
    &.ui-state-focus {
        .ui-icon-background {
            outline: none;
            border-color: $blue-hover;
        }
    }
}

// =================================
//    Checkbox
// =================================
.ui-checkboxradio-label:not(.ui-checkboxradio-radio-label) {
    position: relative;
    cursor: pointer;
    font: 12px / 16px $ReaderRegularPro;
    letter-spacing: 1px;
    padding: 0 0 0 30px;
    display: inline-block;
    color: $grey2;

    .ui-checkboxradio-icon-space {
        display: none;
    }

    .ui-icon-background {
        width: 20px;
        height: 20px;
        overflow: visible;
        background-color: $white;
        border: 2px solid $white;
        outline: 1px solid $black1;
        position: absolute;
        top: 0%;
        left: 1px;
    }

    // active state: inner blue square
    &.ui-state-active {
        .ui-icon-background {
            background-color: $black1;
        }
    }

    // EADA-25 - Lack of focus indicators
    &.ui-state-focus {
        .ui-icon-background {
            outline-color: $blue-hover;
        }
    }
}

.ui-button {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    // Support: IE <= 11
    overflow: visible;
}

// =================================
//    Selectmenu
// =================================
.ui-selectmenu-button {
    position: relative;
    display: block;
    border: 1px solid $black1;
    font: 12px / 40px $ReaderRegularPro;
    letter-spacing: 1px;
    height: 40px;
    padding: 0 40px 0 20px;
    background-color: $white;
    color: $black1;
    width: 100%;
    border-radius: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &::before {
        position: absolute;
        top: 12px;
        right: 20px;
        content: '';
        z-index: 1;
        display: inline-block;
        width: 9px;
        height: 9px;
        border: 1px solid $black1;
        border-width: 1px 0 0 1px;
        transform: rotate(225deg);
    }

    &:focus:not(.ui-selectmenu-button-open) {
        border-color: $blue-hover;
    }

    &.ui-selectmenu-button-open {
        border-width: 1px 1px 0;
        border-radius: 0;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }

    &.ui-selectmenu-button-open::before {
        top: 16px;
        transform: rotate(45deg);
    }

    &:focus {
        outline: 0;
    }
}

.ui-selectmenu-menu {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid $black1;
    border-width: 0 1px 1px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background: $white;
    padding: 0 0 15px;

    &:focus {
        outline: 0;
    }

    .ui-menu {
        font: 12px / 20px $ReaderRegularPro;
        letter-spacing: 1px;
        margin: 0;
        padding: 0 40px 0 20px;
        color: $black1;
        overflow: auto;
        overflow-x: hidden;
        max-height: 200px;

        &:focus {
            outline: 0;
        }
    }

    .ui-menu-item {
        margin: 0 0 2px;
    }

    .ui-menu-item:first-child {
        display: none;
    }

    .ui-menu-item-wrapper.ui-state-active {
        color: $blue-hover;
    }
}

.ui-selectmenu-open {
    display: block;

}
