.footer-newsletter {
    padding: 60px 30px;
    border-top: 1px solid $black1;

    .newsletter-form {
        @include media('>=tablet') {
            max-width: 450px;
            margin: 0 auto;
        }
    }
}

.footer-nav {
    padding: 30px;
    border-top: 1px solid $black1;

    @include media('>=tablet') {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        padding: 10px 30px;
    }
}

.footer-item-list-li {
    margin: 0 0 30px;

    @include media('>=tablet') {
        flex-grow: 1;
        display: inline-block;
        margin: 0 30px 0 0;
    }
}

.footer-item-link {
    font: 14px / 18px $ReaderMediumPro;
    color: $black1;
    text-decoration: none;
}

.footer-social-list {
    display: flex;
    align-items: center;
}

.footer-social-icon {
    margin: 0 10px 0 0;

    &:last-child {
        margin: 0;
    }

    .linkedinsvg {
        width: 30px;
        margin: 0 0 0 10px;
    }
}
