.donation-top {
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
}

.donation-top-wrapper {
    @include media('>=tablet') {
        max-width: 448px;
        margin: 0 auto;
    }

    @include media('>=desktop') {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        max-width: 1160px;
        margin: 0 auto;
        padding: 0 50px;
    }

    @include media('>=desktopLarge') {
        padding: 0;
    }
}

.donation-text {
    text-align: center;
    padding: 40px 30px 50px;
    color: $white;

    @include media('>=tablet') {
        padding: 100px 0 50px;
    }

    @include media('>=desktop') {
        text-align: left;
        flex-grow: 1;
        padding: 50px 50px 50px 0;
    }

    @include media('>=desktopLarge') {
        padding: 80px 120px 80px 0;
    }
}

.donation-title {
    font: 40px / 46px $ReaderMediumPro;

    @include media('>=desktop') {
        max-width: 440px;
    }

    @include media('>=desktopLarge') {
        font: 50px / 70px $ReaderMediumPro;
    }
}

.donation-wave {
    margin: 20px 0 30px;
}

.donation-desc {
    font: 12px / 20px $ReaderRegularPro;
    letter-spacing: 1px;

    @include media('>=desktop') {
        max-width: 428px;
    }
}

.donation-form-wrapper {
    padding: 0 20px 60px;

    @include media('>=tablet') {
        padding: 0 0 100px;
    }

    @include media('>=desktop') {
        width: 440px;
        flex-shrink: 0;
        padding: 87px 0 77px;
    }

    @include media('>=desktopLarge') {
        padding: 157px 0 147px;
    }
}

.donation-form {
    position: relative;  // for loader
    background-color: $white;
    padding: 40px 20px;
    border-radius: 20px;

    @include media('>=tablet') {
        padding: 40px 44px;
    }

    @include media('>=desktop') {
        padding: 40px;
    }

    &.gl_form-disabled {
        opacity: 1;
    }

    &.donation_form-text_loader {
        max-height: 438px;
        overflow: hidden;

        @include media('>=tablet') {
            max-height: 416px;
        }
    }
}

.donation-form_submit {
    margin: 0 0 30px;
}

.donation-form_disclaimer {
    font: 12px / 20px $ReaderRegularPro;
    letter-spacing: 1px;
}

.donation-header {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid $grey4;
    padding: 0 0 20px;
    margin: 0 0 25px;
}

.donation-header_left {
    font: 20px / 26px $ReaderMediumPro;
}

.donation-header_arrow,
.donation-header_text {
    display: inline-block;
}

.donation-header_text {
    margin: 0 10px 0 0;
}

.donation-header_right {
    font: 12px / 20px $ReaderRegularPro;
    letter-spacing: 1px;
    color: $black;
    flex-grow: 1;
    text-align: right;
}

.donation-form_loader {
    border-radius: 20px;

    .donation-loader_text {
        display: none;
    }

    .donation_form-text_loader & {
        background: $white;

        .gl_loader {
            flex-flow: column nowrap;
            justify-content: flex-start;
            padding: 100px 0 0;
        }

        .svgloader {
            display: block;
            margin: 0 0 30px;
        }

        .donation-loader_text {
            display: block;
        }
    }
}

.donation-loader_text {
    font: 14px / 18px $ReaderMediumPro;
    text-align: center;
}

// Donation form: step 1
.donation-form_step1 {
    .error_message-wrapper {
        font: 12px / 20px $ReaderRegularPro;
        text-align: left;
    }
}

.donation-amount_fieldset {
    margin: 0 0 50px;

    &.gl_has-error {
        margin: 0 0 30px;
    }

    .donation-freeamount_field {
        padding: 0 0 5px;
    }
}

.donation-amount_field {
    .group-options_title {
        margin: 0 0 3px;
    }
}

.donation-freeamount_label {
    display: block;
    font: 14px / 18px $ReaderMediumPro;
    margin: 0 0 18px;
}

// Donation form: step 2
.donation-form_step2-fieldset {
    @include media('>=tablet') {
        display: flex;
        flex-flow: row nowrap;
        margin: 0 -5px;
    }

    .gl_form-group {
        flex: 0 0 50%;
        flex-grow: 1;

        @include media('>=tablet') {
            padding: 0 5px 28px;
        }
    }

    .gl_form-group.gl_has-error {
        @include media('>=tablet') {
            padding: 0 5px 8px;
        }
    }
}

// Donation form: step 3
.donation-form_step3 {
    .donation-form_submit {
        margin: 0 0 20px;
    }

    .error_message-wrapper {
        text-align: left;
        padding: 0 0 2px;
    }

    &.donation_form-error {
        .donation-form_submit {
            margin: 0 0 2px;
        }
    }
}

.donation-form_step3-btn {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    line-height: 14px;
}

.donation-form_step3-btn_icon {
    height: 14px;
    margin: 0 8px 0 0;
}

.donation-form_secure {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    font: 12px / 20px $ReaderRegularPro;
    letter-spacing: 1px;

    .svglock {
        .st0 {
            fill: $black1;
        }
    }
}

.donation-form_secure-icon {
    height: 14px;
    margin: 0 5px 0 0;
}

// Donation bottom section
.donation-bottom {
    @include media('>=desktop') {
        display: flex;
        flex-flow: row-reverse nowrap;
        align-items: stretch;
    }

    @include media('>=desktopLarge') {
        max-width: 1440px;
        margin: 0 auto;
    }
}

.donation-bottom-col {
    @include media('>=desktop') {
        flex: 0 0 50%;
        max-width: 50%;
    }
}

.donation-bottom-text {
    padding: 60px 40px;

    @include media('>=tablet') {
        padding: 100px;
    }

    @include media('>=desktop') {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
    }

    @include media('>=desktopLarge') {
        padding: 40px 140px;
    }
}

.donation-bottom-title {
    font: 28px / 36px $ReaderMediumPro;
    margin: 0 0 24px;
}

.donation-bottom-desc {
    font: 12px / 20px $ReaderRegularPro;
    letter-spacing: 1px;
    margin: 0 0 30px;
}

.donation-social-list {
    display: flex;
    align-items: center;
    margin: 0 0 20px;

    @include media('>=tablet') {
        margin: 0;
    }
}

.donation-social-icon {
    margin: 0 10px 0 0;

    &:last-child {
        margin: 0;
    }

    .linkedinsvg {
        width: 30px;
        margin: 0 0 0 10px;
    }
}

// Programs section: snake svg styles
.donation-bottom-snake {
    right: 0;

    @include media('<tablet') {
        bottom: 0;
        width: 133px;
        height: 296px;

        .svgsnake {
            width: 267px;
            height: 296px;
        }
    }

    @include media('>=tablet', '<desktop') {
        @include absolute-vertical-center;
        width: 258px;
        height: auto;

        .svgsnake {
            width: 476px;
            height: 528px;
        }
    }

    @include media('>=desktop') {
        bottom: 0;
        width: 220px;
        height: 429px;

        .svgsnake {
            width: 388px;
            height: 430px;
        }
    }
}
