.donation-confirm {
    background-color: $white;
    padding: 60px 20px;
    border-radius: 20px;
    text-align: center;

    @include media('>=tablet') {
        padding: 75px 44px;
    }

    @include media('>=desktop') {
        padding: 75px 40px;
    }
}

.donation-confirm_title {
    font: 20px / 26px $ReaderMediumPro;
    margin: 0 0 20px;
}

.donation-confirm_text {
    font: 12px / 20px $ReaderRegularPro;
    letter-spacing: 1px;
}

.donation-confirm_social-title {
    font: 14px / 18px $ReaderMediumPro;
    margin: 20px 0;
}

.donation-confirm_social-list {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    .donation-confirm_social-item {
        margin: 0 10px 0 0;

        &:last-child {
            margin: 0;
        }

        .linkedinsvg {
            width: 30px;
            margin: 0 0 0 10px;
        }
    }
}

.donation-failure_text {
    margin: 0 0 20px;
}

.donation-failure_mailto {
    font-family: $ReaderMediumPro;
}

.donation-failure_contact {
    width: auto;
}
