// notfound page styles
.notfound-wrapper {
    margin: 100px 30px;

    @include media('>=tablet') {
        max-width: 448px;
        margin: 100px auto;
    }

    @include media('>=desktop') {
        max-width: 498px;
    }
}

.notfound-wave {
    margin: 20px 0 30px;
    height: 15px;
}

.notfound-text {
    text-align: center;
}

.notfound-title {
    font: 40px / 46px $ReaderMediumPro;
    margin: 0;

    @include media('>=desktop') {
        font-size: 50px;
        line-height: 70px;
    }
}

.notfound-desc {
    font: 12px / 20px $ReaderRegularPro;
    letter-spacing: 1px;
    margin: 0;

    @include media('>=desktop') {
        max-width: 440px;
        margin: 0 auto;
    }
}

.notfound-buttons {
    margin: 30px 0 0;

    @include media('>=tablet') {
        margin: 50px 0 0;
    }

    @include media('>=desktop') {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        margin: 30px 0 0;
    }
}

.notfound-link-btn {
    display: block;
    margin: 0 0 15px;

    &:last-child {
        margin: 0;

        @include media('>=desktop') {
            margin: 0 5px;
        }
    }

    @include media('>=desktop') {
        flex: 0 0 33.33%;
        max-width: 33.33%;
        margin: 0 5px;
    }
}
