// =================================
//    Breakpoints
// =================================
$phone: 320px;
$tablet: 768px;
$desktop: 1024px;
$desktopMedium: 1200px;
$desktopLarge: 1358px;

// =================================
//    Colors
// =================================
$blue: #0c315d;
$blue-hover: #1b65b1;
$blue1: #0074f7;
$blue2: #0088ff;
$blue3: #006bd1;
$blue4: #0c73e3;
$blue5: #1b7ee9;
$grey1: #d8d8d8;
$grey2: #636363;
$grey3: #f9f9f9;
$grey4: #b1b1b1;
$red: #e50404;
$white: #fff;
$black: #000;
$black1: #202127;
$transparent-black1: rgba(32, 33, 33, 0.5);
$transparent-white: rgba(255, 255, 255, 0.85);
$transparent-white2: rgba(255, 255, 255, 0.15);
$error-color: $blue4;

// Header submenu tile
$orange-tile: #f99057;
$green-tile: #01bd8a;
$yellow-tile: #ffcc37;

// VF loader colors
$loader-blue1: #2d50a7;
$loader-blue2: #355ec9;
$loader-blue3: #3d6deb;
$loader-blue4: #5286fa;
$loader-blue5: #73a1fb;
$loader-blue6: #c4d9fd;

// =================================
//    Fonts
// =================================
$ReaderRegularPro: ReaderRegularPro, Arial, sans-serif;
$ReaderMediumPro: ReaderMediumPro, Arial, sans-serif;
$Arial: Arial, sans-serif;
