// =================================
//    Absolute center
// =================================
@mixin absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin absolute-horizont-center {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

@mixin absolute-vertical-center {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

// =================================
//    Carousel controls: arrows
// =================================
@mixin carousel-arrow($direction: 'next') {
    width: 40px;
    height: 40px;
    display: block;
    margin: 0 auto;
    border: 0;
    background-color: transparent;
    padding: 0;
    text-align: center;

    &::before {
        content: '';
        display: inline-block;
        width: 20%;
        height: 20%;
        border: 1px solid $black1;
        border-width: 1px 0 0 1px;

        @if $direction == 'next' {
            transform: rotate(-225deg);
            margin-right: 10%;
        } @else if $direction == 'prev' {
            transform: rotate(-45deg);
            margin-left: 10%;
        } @else if $direction == 'up' {
            transform: rotate(45deg);
        } @else if $direction == 'down' {
            transform: rotate(225deg);
        }
    }
}
