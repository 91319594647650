.application-page {
    background-color: $orange-tile;
    background-position: top center;
    background-size: cover;
    padding: 60px 20px 100px;

    @include media('>=desktop') {
        padding: 60px 60px 180px;
    }
}

.application-header {
    text-align: center;
    margin: 0 10px 60px;

    @include media('>=tablet') {
        max-width: 448px;
        margin: 0 auto 100px;
    }

    @include media('>=desktop') {
        max-width: 600px;
    }
}

.application-icon {
    margin: 0 0 30px;
}

.application-title {
    font: 28px / 36px $ReaderMediumPro;
    margin: 0 0 35px;
}

.application-desc {
    font: 12px / 20px $ReaderRegularPro;
    letter-spacing: 1px;

    @include media('>=desktop') {
        max-width: 500px;
        margin: 0 auto;
    }
}

.application-form {
    position: relative; // for loader
    background-color: $white;
    padding: 60px 20px;
    border-radius: 20px;

    @include media('>=tablet') {
        max-width: 568px;
        margin: 0 auto;
        padding: 100px 60px 85px;
    }

    @include media('>=desktop') {
        max-width: 1080px;
        padding: 100px 120px 75px;
    }

    .application-information {
        margin: 0 0 30px;
    }

    .application-form_loader {
        border-radius: 20px;

        .gl_loader {
            align-items: flex-end;
            padding: 0 0 80px;
        }

        &.visible {
            display: block;
        }
    }
}

.application-form_header {
    border-bottom: 1px solid $grey4;
    padding: 0 0 40px;
    margin: 0 0 35px;

    @include media('>=tablet') {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;
    }
}

.application-form_title {
    font: 20px / 26px $ReaderMediumPro;
    margin: 0 0 10px;

    @include media('>=tablet') {
        margin: 0;
    }
}

.application-form_required {
    font: 12px / 20px $ReaderRegularPro;
    letter-spacing: 1px;

    @include media('>=tablet') {
        flex-grow: 1;
        text-align: right;
        padding: 0 0 0 10px;
    }
}

.application-form_fieldset {
    border-bottom: 1px solid $grey4;
    padding: 0 0 30px;
    margin: 0 0 40px;

    @include media('>=desktop') {
        display: flex;
        flex-flow: row wrap;
    }

    .gl_form-group {
        @include media('>=desktop') {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }

    .gl_form-group:nth-child(odd) {
        @include media('>=desktop') {
            padding: 0 20px 28px 0;
        }

        &.gl_has-error {
            @include media('>=desktop') {
                padding: 0 20px 8px 0;
            }
        }
    }

    .gl_form-group:nth-child(even) {
        @include media('>=desktop') {
            padding: 0 0 28px 20px;
        }

        &.gl_has-error {
            @include media('>=desktop') {
                padding: 0 0 8px 20px;
            }
        }
    }

    .application-bottom_field {
        .gl_input-wrapper {
            @include media('>=desktop') {
                margin: 30px 0 0;
            }
        }
    }
}

.application-submit {
    text-align: center;

    @include media('>=tablet') {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
    }

    .grecaptcha-badge {
        margin: 0 auto 30px;

        @include media('>=tablet') {
            margin: 0;
        }
    }
}

.application-btn {
    @include media('>=tablet') {
        width: 135px;
    }

    @include media('>=desktop') {
        width: 295px;
    }
}
