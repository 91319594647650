.application-confirm {
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
}

.application-confirm_wrapper {
    @include media('>=tablet') {
        max-width: 448px;
        margin: 0 auto;
    }

    @include media('>=desktop') {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        max-width: 1160px;
        margin: 0 auto;
        padding: 0 50px;
    }

    @include media('>=desktopLarge') {
        padding: 0;
    }
}

.application-confirm_text {
    text-align: center;
    padding: 60px 30px;

    @include media('>=tablet') {
        padding: 60px 0 50px;
    }

    @include media('>=desktop') {
        text-align: left;
        flex-grow: 1;
        padding: 50px 50px 50px 0;
    }

    @include media('>=desktopLarge') {
        padding: 80px 120px 80px 0;
    }
}

.application-confirm_title {
    font: 28px / 36px $ReaderMediumPro;
    margin: 0 0 35px;

    @include media('>=desktop') {
        max-width: 440px;
    }

    @include media('>=desktopLarge') {
        font: 40px / 50px $ReaderMediumPro;
    }
}

.application-confirm_icon {
    margin: 0 0 30px;
}

.application-confirm_desc {
    font: 12px / 20px $ReaderRegularPro;
    letter-spacing: 1px;

    @include media('>=desktop') {
        max-width: 428px;
    }
}

.application-confirm_main {
    padding: 0 20px 100px;

    @include media('>=tablet') {
        padding: 0 0 100px;
    }

    @include media('>=desktop') {
        width: 440px;
        flex-shrink: 0;
        padding: 87px 0 77px;
    }

    @include media('>=desktopLarge') {
        padding: 157px 0 147px;
    }
}

.application-confirm_main-wrap {
    background-color: $white;
    padding: 60px 20px;
    border-radius: 20px;
    text-align: center;

    @include media('>=tablet') {
        padding: 75px 44px;
    }

    @include media('>=desktop') {
        padding: 75px 40px;
    }
}

.application-confirm_main-title {
    font: 20px / 26px $ReaderMediumPro;
    margin: 0 0 20px;
}

.application-confirm_main-text {
    font: 12px / 20px $ReaderRegularPro;
    letter-spacing: 1px;
}
