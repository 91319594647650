// home_text-and-image_second-block slot with textimage.isml: snake svg styles
.home-text_image-snake-1 {
    @include media('<tablet') {
        top: 0;
        right: 0;
        width: 232px;
        height: 148px;

        .svgsnake {
            position: relative;
            top: calc(-1 * (346px - 148px)); // height from Zeplin SVG dimension - height from Zeplin container dimension
            width: 326px;
            height: 346px;
        }
    }

    @include media('>=tablet', '<desktopLarge') {
        top: 0;
        right: 0;
        width: 339px;
        height: 198px;

        .svgsnake {
            position: relative;
            top: calc(-1 * (488px - 198px)); // height from Zeplin SVG dimension - height from Zeplin container dimension
            width: 476px;
            height: 488px;
        }
    }

    @include media('>=desktopLarge') {
        @include absolute-vertical-center;
        right: 0;
        width: 343px;
        height: 524px;

        .svgsnake {
            width: 478px;
            height: 524px;
        }
    }
}

// Programs section
.home_programs-wrapper {
    background-color: $grey3;
    padding: 100px 0;
    overflow: hidden; // to truncate snake-like svg
}

.home_programs-content {
    overflow: visible;

    @include media('>=desktop') {
        max-width: 1440px;
        margin: 0 auto;
    }
}

.home_programs-title {
    text-align: center;
    font: 28px / 36px $ReaderMediumPro;
    margin: 0 0 34px;

    @include media('>=desktop') {
        margin: 0 0 44px;
    }
}

.home_programs-tiles {
    @include media('>=desktop') {
        padding: 0 30px;
        display: flex;
        flex-flow: row nowrap;
    }
}

.home_programs-tile {
    padding: 30px 30px 0;

    &:first-child {
        padding: 0 30px;

        @include media('>=tablet') {
            padding: 0 100px;
        }

        @include media('>=desktop') {
            padding: 0 10px;
        }
    }

    @include media('>=tablet') {
        padding: 30px 100px 0;
    }

    @include media('>=desktop') {
        flex: 0 0 33.33%;
        max-width: 33.33%;
        padding: 0 10px;
        display: flex;
        flex-flow: column nowrap;
    }
}

.home_programs-img {
    position: relative;
}

.home_programs-icon {
    @include absolute-center;
}

.home_programs-icon_seaweed {
    .svgseaweed {
        width: 170px;
        height: 157px;
    }
}

.home_programs-icon_foundationturtle {
    .svgfoundationturtle {
        width: 200px;
        height: 197px;
    }
}

.home_programs-icon_tshirt {
    .svgtshirt {
        width: 210px;
        height: 180px;
    }
}

.home_programs-tile_content {
    position: relative; // to overlap snake-like svg
    padding: 30px 40px;
    background-color: $white;

    @include media('>=tablet') {
        padding: 30px 60px;
    }

    @include media('>=desktop') {
        flex-grow: 1;
        padding: 30px 30px 30px 40px;
    }

    @include media('>=desktopLarge') {
        padding: 30px 40px 30px 50px;
    }
}

.home_programs-tile_title-wrap {
    margin: 0 0 10px;
}

.home_programs-tile_title.gl_lines-text {
    font: 20px / 26px $ReaderMediumPro;
}

.home_programs-tile_text {
    .home_programs-tile_link {
        width: auto;
    }
}

.home_programs-tile_desc {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1px;
    margin: 0 0 20px;
}

// Programs section: snake svg styles
.home_programs-title-snaketop {
    right: 0;
    z-index: 0;

    @include media('<tablet') {
        top:-46px;
        width: 183px;
        height: 425px;

        .svgsnake {
            width: 356px;
            height: 426px;
        }
    }

    @include media('>=tablet', '<desktopLarge') {
        top: 0;
        width: 345px;
        height: 557px;

        .svgsnake {
            position: relative;
            top: calc(-1 * (667px - 557px)); // height from Zeplin SVG dimension - height from Zeplin container dimension
            width: 558px;
            height: 667px;
        }
    }

    @include media('>=desktopLarge') {
        top: 36px;
        width: 423px;
        height: 667px;

        .svgsnake {
            width: 558px;
            height: 667px;
        }
    }
}

.home_programs-title-snakebottom {
    z-index: 0;

    @include media('<tablet') {
        top: -10px; // 40px from Zeplin - 30px on gl_snake-parent
        left: 0;
        width: 169px;
        height: 425px;

        .svgsnake {
            transform: translateX(-187px); // 356px - 169px, no calc for translateX...
            width: 356px;
            height: 426px;
        }
    }

    @include media('>=tablet') {
        top: -37px; // 67px from Zeplin - 30px padding on gl_snake-parent
        left: 0;
        width: 246px;
        height: 667px;

        .svgsnake {
            transform: translateX(-312px); // 558px - 246px, no calc for translateX...
            width: 558px;
            height: 667px;
        }
    }

    @include media('>=desktop') {
        display: none;
    }
}

.home_programs-tile.gl_snake-parent {
    overflow: visible;
}

// home_initiatives_fourth-block slot with initiatives_carousel.isml
.home-initiatives_carousel {
    .initiatives_carousel-title {
        color: $white;
    }
}

.initiatives_carousel-slide_img {
    width: 100%;
}

// home_text-and-image_fifth-block slot with textimage.isml: snake svg styles
.home-text_image-2 {
    .gl_snake-parent  {
        @include media('>=desktop') {
            overflow: visible;
        }
    }
}

.home-text_image-snake-2 {
    right: 0;

    @include media('<tablet') {
        top: -55px;
        width: 132px;
        height: 359px;

        .svgsnake {
            width: 326px;
            height: 359px;
        }
    }

    @include media('>=tablet', '<desktop') {
        top: 0;
        width: 261px;
        height: 454px;

        .svgsnake {
            position: relative;
            top: -80px;
            width: 478px;
            height: 527px;
        }
    }

    @include media('>=desktop', '<desktopLarge') {
        top: 0;
        width: 261px;
        height: 454px;

        .svgsnake {
            position: relative;
            top: -100px;
            width: 478px;
            height: 527px;
        }
    }

    @include media('>=desktopLarge') {
        @include absolute-vertical-center;
        width: 343px;
        height: 526px;

        .svgsnake {
            width: 478px;
            height: 527px;
        }
    }
}
