// fashion second block
.programs-fashion-secondBlock {
    .text_image-col.text_block {
        @include media('<tablet') {
            padding: 60px 40px 63px;
        }

        @include media('>=tablet', '<desktop') {
            padding: 100px;
        }

        @include media('>=desktop', '<desktopLarge') {
            padding: 60px 40px;
        }

        @include media('>=desktopLarge') {
            padding: 60px 80px;
        }
    }

    .text_image-text {
        position: relative; // so the text is not under the icon
    }

    .gl_program-icon-wrapper {
        width: 150px;
        height: 128px;

        @include media ('<tablet') {
            top: 20px;
            right: 21px;
        }

        @include media('>=tablet', '<desktop') {
            top: 42px;
            right: 61px;
        }

        @include media('>=desktop', '<desktopLarge') { // not in Zeplin, eyeballed
            top: 12px;
            right: calc(50% + 30px);
        }

        @include media('>=desktopLarge') {
            top: 20px;
            right: calc(50% + 30px);
            width: 170px;
            height: 145px;

            .svgtshirt {
                width: 170px;
                height: 145px;
            }
        }
    }
}

.programs-fashion-secondBlock-shape-1 {
    top: 0;

    @include media('<tablet') {
        left: 40px;
        width: 326px; // Zeplin values for the container
        height: 148px;
    }

    @include media('>=tablet', '<desktop') {
        left: 200px;
        width: 326px;
        height: 151px;
    }

    @include media('>=desktop', '<desktopLarge') { // not in Zeplin, eyeballed
        left: 70px;
        width: 326px;
        height: 121px;
    }

    @include media('>=desktopLarge') {
        left: 150px;
        width: 326px;
        height: 147px;
    }

    &.gl_snake-wrapper {
        z-index: 0;
    }

    .svgsnake {
        position: relative;

        @include media('<tablet') {
            top: calc(-1 * (352px - 148px)); // SVG height - container height
            width: 326px; // Zeplin values for the SVG
            height: auto; // ~352px
        }

        @include media('>=tablet', '<desktop') {
            top: calc(-1 * (352px - 151px));
            width: 326px;
            height: auto; // ~352px
        }

        @include media('>=desktop', '<desktopLarge') { // not in Zeplin, eyeballed
            top: calc(-1 * (352px - 121px));
            width: 326px; // not in Zeplin
            height: auto; // ~352px
        }

        @include media('>=desktopLarge') {
            top: calc(-1 * (352px - 147px));
            width: 326px; // not in Zeplin
            height: auto; // ~352px
        }

        .st0 {
            fill: $transparent-white2;
        }
    }
}

.programs-fashion-secondBlock-shape-2 {
    bottom: 0;

    @include media('<tablet') {
        left: 19px;
        width: 326px; // Zeplin values for the container
        height: 102px;
    }

    @include media('>=tablet', '<desktop') {
        left: 104px;
        width: 326px;
        height: 102px;
    }

    @include media('>=desktop', '<desktopLarge') { // not in Zeplin, eyeballed
        left: 54px;
        width: 326px;
        height: 104px;
    }

    @include media('>=desktopLarge') {
        left: 54px;
        width: 326px;
        height: 104px;
    }

    &.gl_snake-wrapper {
        z-index: 0;
    }

    .svgsnake {
        position: relative;

        @include media('<tablet') {
            top: -124px; // not in Zeplin
            width: 326px; // Zeplin values for the SVG
            height: auto;
        }

        @include media('>=tablet', '<desktop') {
            top: -124px; // not in Zeplin
            width: 326px; // not in Zeplin
            height: auto;
        }

        @include media('>=desktop', '<desktopLarge') { // not in Zeplin, eyeballed
            top: -124px; // not in Zeplin
            width: 326px; // not in Zeplin
            height: auto;
        }

        @include media('>=desktopLarge') {
            top: -124px; // not in Zeplin
            width: 326px; // not in Zeplin
            height: auto;
        }

        .st0 {
            fill: $transparent-white2;
        }
    }
}

.programs-fashion-thirdBlock {
    text-align: center;

    @include media('<tablet') {
        padding: 100px 30px;
    }

    @include media('>=tablet', '<desktop') {
        padding: 100px 160px;
    }

    @include media('>=desktop', '<desktopLarge') {
        padding: 100px 160px;
    }

    @include media('>=desktopLarge') {
        max-width: 1440px;
        margin: 0 auto;
        padding: 100px 370px;
    }

    .text-only-title {
        @include media('<tablet') {
            margin-bottom: 38px;
        }

        @include media('>=tablet', '<desktop') {
            margin-bottom: 30px;
        }

        @include media('>=desktop') {
            margin-bottom: 34px;
        }
    }
}

.programs-fashion-thirdBlock-shape {
    right: 0;

    @include media('<tablet') {
        top: 0;
        width: 217px; // Zeplin values for the container
        height: 111px;
    }

    @include media('>=tablet', '<desktop') {
        top: 0;
        width: 297px;
        height: 144px;
    }

    @include media('>=desktop', '<desktopLarge') { // not in Zeplin, eyeballed
        top: 0;
        width: 297px;
        height: 144px;
    }

    @include media('>=desktopLarge') {
        top: 40px;
        width: 310px;
        height: 289px;
    }

    .svgsnake {
        position: relative;

        @include media('<tablet') {
            top: calc(-1 * (222px - 111px));
            width: 513px; // Zeplin values for the SVG
            height: 222px;
        }

        @include media('>=tablet', '<desktop') {
            top: calc(-1 * (289px - 144px));
            width: 667px; // Zeplin values for the SVG
            height: 289px;
        }

        @include media('>=desktop', '<desktopLarge') { // not in Zeplin, eyeballed
            top: calc(-1 * (289px - 144px));
            width: 667px; // Zeplin values for the SVG
            height: 289px;
        }

        @include media('>=desktopLarge') {
            width: 668px; // Zeplin values for the SVG
            height: 290px;
        }

        .st0 {
            fill: $yellow-tile;
        }
    }
}

// outer snakes on detail_pages-block
.fashion-detail_pages-block {
    .text_image-outer_snakes {
        .svgsnake .st0 {
            fill: $yellow-tile;
        }
    }
}
