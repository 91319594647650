html,
body {
    height: 100%;
    width: 100%;
}

* {
    box-sizing: border-box;
}

.gl_content {
    color: $black1;
    font-family: $ReaderRegularPro;
}

button {
    background: 0;
    border: 0;
    padding: 0;
}

img {
    width: auto;
    max-width: 100%;
    vertical-align: top;
}

video {
    display: block;
}

h1 {
    font: 40px / 46px $ReaderMediumPro;

    @include media('>=desktop') {
        font-size: 50px;
        line-height: 70px;
    }
}

// to be visible for screen readers only
.offscreen {
    position: absolute !important;
    top: -9999px !important;
    left: -9999px !important;
}

.vf-logo {
    max-height: 100%;
}

// =================================
//    Styleguide
// =================================
a,
.gl_fake-link {
    text-decoration: none;
    color: $black1;

    &:hover {
        font-weight: bold;
    }
}

.gl_flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.gl_table {
    border: 1px solid $black1;

    th, td {
        padding: 20px;
        border: 1px solid $black1;
    }

    th {
        text-align: left;
    }

    td {
        vertical-align: top;
    }
}

// =================================
//    Main wrapper for all pages
// =================================
.gl_main-wrapper {
    margin: 0 auto;
    max-width: 1920px;
}

// =================================
//    global hide class
// =================================
.gl_hidden,
.hidden {
    display: none !important;
}

// =================================
//    Desktop only and mobile only
//   !important is used to overpower custom styles on elements
//   on which gl_desktop-only and gl_mobile-only will be used
// =================================
.gl_desktop-only {
    @include media('<desktop') {
        display: none !important;
    }
}

.gl_tablet-only {
    @include media('>=tablet') {
        display: none !important;
    }
}

.gl_mobile-only {
    @include media('>=desktop') {
        display: none !important;
    }
}

.gl_mobile-hidden-only {
    @include media('<tablet') {
        display: none !important;
    }
}

.gl_showOnPhone {
    @include media('>=tablet') {
        display: none !important;
    }
}

.gl_showOnTablet {
    display: none;

    @include media('>=tablet', '<desktop') {
        display: block !important;
    }
}

.gl_mobile-image-only {
    display: block !important;

    @include media('>=tablet') {
        display: none !important;
    }
}

.gl_tablet-image-only {
    display: none;

    @include media('>=tablet', '<desktop') {
        display: block !important;
    }
}

.gl_desktop-image-only {
    display: none;

    @include media('>=desktop', '<1200px') {
        display: block !important;
    }
}

.gl_desktoplarge-image-only {
    display: none;

    @include media('>=1200px', '<1500px') {
        display: block !important;
    }
}

.gl_desktopextralarge-image-only {
    display: none;

    @include media('>=1500px') {
        display: block !important;
    }
}

.gl_desktoplargeandextralarge-image-only {
    display: none;

    @include media('>=1200px') {
        display: block !important;
    }
}

.gl_loader-parent {
    position: relative;
}

// SVG loader with css animation: change blue colors
@mixin loader-animation (
    $name: 'fillchange',
    $color1: $loader-blue1,
    $color2: $loader-blue2,
    $color3: $loader-blue3,
    $color4: $loader-blue4,
    $color5: $loader-blue5,
    $color6: $loader-blue6,
    $color7: $white
) {
    @keyframes #{$name} {
        0% {
            fill: $color1;
        }

        17% {
            fill: $color2;
        }

        33% {
            fill: $color3;
        }

        50% {
            fill: $color4;
        }

        67% {
            fill: $color5;
        }

        83% {
            fill: $color6;
        }

        100% {
            fill: $color7;
        }
    }

    animation-name: #{$name};
}

.gl_loader-wrapper {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $transparent-white;
    z-index: 10;

    .gl_loader {
        height: 100%;
    }

    .st {
        animation-duration: 2s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
    }

    .st0 {
        @include loader-animation;
    }

    .st1 {
        @include loader-animation(
            'fillchange-1',
            $loader-blue2,
            $loader-blue3,
            $loader-blue4,
            $loader-blue5,
            $loader-blue6,
            $white,
            $loader-blue1
        );
    }

    .st2 {
        @include loader-animation(
            'fillchange-2',
            $loader-blue3,
            $loader-blue4,
            $loader-blue5,
            $loader-blue6,
            $white,
            $loader-blue1,
            $loader-blue2,
        );
    }

    .st3 {
        @include loader-animation(
            'fillchange-3',
            $loader-blue4,
            $loader-blue5,
            $loader-blue6,
            $white,
            $loader-blue1,
            $loader-blue2,
            $loader-blue3,
        );
    }

    .st4 {
        @include loader-animation(
            'fillchange-4',
            $loader-blue5,
            $loader-blue6,
            $white,
            $loader-blue1,
            $loader-blue2,
            $loader-blue3,
            $loader-blue4,
        );
    }

    .st5 {
        @include loader-animation(
            'fillchange-5',
            $loader-blue6,
            $white,
            $loader-blue1,
            $loader-blue2,
            $loader-blue3,
            $loader-blue4,
            $loader-blue5,
        );
    }

    .st6 {
        @include loader-animation(
            'fillchange-6',
            $white,
            $loader-blue1,
            $loader-blue2,
            $loader-blue3,
            $loader-blue4,
            $loader-blue5,
            $loader-blue6,
        );
    }
}

// =================================
//    Global button styles
// =================================
.gl_btn {
    display: inline-block;
    width: 100%;
    font: 14px / 40px $ReaderRegularPro;
    color: $black1;
    background-color: $white;
    border: 0;
    border-radius: 20px;
    text-align: center;
    height: 40px;
    padding: 0 30px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
    position: relative;

    &:focus {
        outline: 0;
        box-shadow: 0 0 3px 2px $blue-hover;
    }

    &:hover {
        font-weight: bold;
    }
}

.gl_btn-blue {
    background-color: $blue4;
    color: $white;
}

.gl_btn-small {
    height: 30px;
    line-height: 30px;
}

// =================================
//    Form styles
// =================================
textarea {
    border: 1px solid $black1;
    border-radius: 20px;
    font: 12px / 20px $ReaderRegularPro;
    letter-spacing: 1px;
    color: $grey2;
    width: 100%;
    resize: none;
    padding: 15px 20px;

    &:focus {
        outline: 0;
        border-color: $blue-hover;
    }
}

.gl-textarea_counter {
    font: 12px / 20px $ReaderRegularPro;
    letter-spacing: 1px;
    color: $grey2;
}

input[type="text"],
input[type="email"],
input[type="number"],
input[type="tel"] {
    border: 1px solid $black1;
    font: 12px / 14px $ReaderRegularPro;
    letter-spacing: 1px;
    height: 40px;
    padding: 0 5px 0 20px;
    color: $grey2;
    width: 100%;
    position: relative;
    border-radius: 20px;

    &:focus {
        outline: 0;
        border-color: $blue-hover;
    }

    .gl_has-success & {
        padding: 0 30px 0 20px;
    }
}

input[type="number"] {
    // hide the spinner in Firefox
    -moz-appearance: textfield;

    // hide the spinner Chrome, Safari, Edge, Opera
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

input[type="file"] {
    width: 100%;
    opacity: 0;
    padding: 0 70px 0 20px;
    line-height: 40px;

    // trick to make the whole area clickable
    position: relative;
    z-index: 1;
}

.gl-input_file-fake {
    border: 1px solid $black1;
    font: 12px / 40px $ReaderRegularPro;
    letter-spacing: 1px;
    height: 40px;
    color: $grey2;
    width: 100%;
    position: relative;
    border-radius: 20px;

    &.focus {
        outline: 0;
        border-color: $blue-hover;
    }
}

.gl-input_file-value {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 70px 0 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.gl-input_file-icon {
    position: absolute;
    top: 11px;
    right: 0;
    width: 60px;
    height: 20px;
    border-left: 1px solid $black1;
    padding: 0 20px;
}

.gl-description {
    font: 12px / 20px $ReaderRegularPro;
    letter-spacing: 1px;
    color: $grey2;
}

.gl_tel {
    // put float label above outline around the .iti__selected-flag
    label {
        z-index: 1;
    }
}

.iti {
    .gl_input-wrapper & {
        display: block;
    }

    &.iti--allow-dropdown {
        input {
            padding: 0 30px 0 105px !important;
        }
    }

    .iti__selected-flag {
        padding: 0 20px;

        &::after {
            display: block;
            content: '';
            position: absolute;
            top: 10px;
            right: 0;
            width: 1px;
            background-color: $black1;
            height: 20px;
        }

        .iti__flag {
            margin: 0 10px 0 0;
        }

        .iti__arrow {
            width: 9px;
            height: 9px;
            border: 1px solid $black1;
            border-width: 1px 0 0 1px;
            transform: rotate(225deg);
            margin-top: -3px;
        }
    }
}

.iti-mobile {
    .iti--container {
        // above the sticky mobile panel
        z-index: 26000;
    }
}

// Safari Support
.gl_checkbox {
    input[type=checkbox] {
        border: 0;
        width: 0;
    }
}

// Safari Support
.gl_radio {
    input[type=radio] {
        border: 0;
        width: 0;
    }
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid $black1;
    font: 12px / 40px $ReaderRegularPro;
    letter-spacing: 1px;
    height: 40px;
    padding: 0 40px 0 20px;
    color: $black1;
    width: 100%;
    position: relative;
    border-radius: 20px;

    &:focus {
        outline: 0;
        border-color: $blue-hover;
    }
}

.gl_select {
    .gl_input-wrapper {
        position: relative;
    }
}

.gl_select:not(.gl_select-custom) {
    .gl_input-wrapper {
        &::before {
            position: absolute;
            top: 12px;
            right: 20px;
            content: '';
            z-index: 1;
            display: inline-block;
            width: 9px;
            height: 9px;
            border: 1px solid $black1;
            border-width: 1px 0 0 1px;
            transform: rotate(225deg);
            pointer-events: none; // critical, else clicking on the triangle does not trigger the <select>
        }
    }
}

.gl_checkbox {
    label {
        font: 12px / 16px $ReaderRegularPro;
        color: $grey2;
    }
}

.gl_checkbox.gl_checkbox-buttons {
    .group-options_checkbox {
        display: flex;
        flex-flow: row wrap;
        margin: 0 -5px;
    }

    .group-option {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0 5px;
        margin: 15px 0 0;
    }

    label {
        display: inline-block;
        cursor: pointer;
        width: 100%;
        text-align: center;
        height: 30px;
        border-radius: 15px;
        border: solid 1px $black1;
        font: 14px / 30px $ReaderMediumPro;
        padding: 0 15px;
    }

    .gl-input_checkbox:checked + label {
        color: $blue4;
        border-color: $blue4;
    }

    .gl-input_checkbox:focus + label {
        border-color: $blue4;
    }
}

.gl_radio {
    .group-options_radio {
        display: flex;
        flex-flow: row wrap;
    }
}

.gl_radio.gl_radio-buttons {
    .group-options_radio {
        margin: 0 -5px;
    }

    .group-option {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0 5px;
        margin: 15px 0 0;
    }

    label {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        width: 100%;
        text-align: center;
        min-height: 30px;
        height: 100%;
        border-radius: 15px;
        border: solid 1px $black1;
        font: 14px / 18px $ReaderMediumPro;
        padding: 5px 15px;
    }

    .gl-input_radio:checked + label {
        color: $blue4;
        border-color: $blue4;
    }

    .gl-input_radio:focus + label {
        border-color: $blue4;
    }
}

.gl_form-group {
    padding-bottom: 28px;

    .gl_error-message {
        display: block;
        font: 12px / 20px $ReaderRegularPro;
        letter-spacing: 1px;
        color: $error-color;
    }

    &.gl_has-error {
        padding-bottom: 8px;
    }
}

.form-submit-error {
    font: 12px / 20px $ReaderRegularPro;
    letter-spacing: 1px;
    color: $error-color;
    margin: 0 0 10px;
}

.error_message-wrapper {
    color: $error-color;
    font-size: 14px;
    text-align: center;
    letter-spacing: 1px;
}

.success_message-wrapper {
    font-family: $ReaderMediumPro;
    font-size: 14px;
    color: $blue-hover;
    text-align: center;
}

.gl_input-wrapper {
    position: relative;
    margin: 0;
    width: 100%;
}

.gl_input-success-container {
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    display: none;

    .gl_has-success & {
        display: flex;
    }
}

.gl_input-success {
    position: relative;
    width: 13px;
    height: 9px;
    margin: 0 auto;

    .gl_has-success & {
        &::before {
            content: '';
            width: 5px;
            height: 2px;
            display: block;
            background-color: $black1;
            position: absolute;
            left: 0;
            bottom: 0;
            transform-origin: bottom right;
            transform: rotate(45deg);
        }

        &::after {
            content: '';
            width: 9px;
            height: 2px;
            display: block;
            background-color: $black1;
            position: absolute;
            left: 6px;
            bottom: 0;
            transform-origin: bottom left;
            transform: rotate(-45deg);
        }
    }
}

.gl_form-disabled {
    pointer-events: none;
    opacity: 0.5;
}

.group-options_title {
    font: 14px / 18px $ReaderMediumPro;
}

.group-option {
    margin: 20px 15px 0 0;
}

// =================================
//  Floating labels
// =================================
.gl_floating-label {
    position: absolute;
    top: 50%;
    left: 20px;
    pointer-events: none;
    transition: 0.2s ease all;
    transform: translateY(-50%);
    background: $white;
    padding: 0;
    color: $black1;
    width: calc(100% - 25px);
    text-align: left;
    font: 12px / 16px $ReaderMediumPro;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    max-height: 32px;
    overflow: hidden;

    .gl-input:focus ~ &,
    .filled &,
    .gl-textarea ~ &,
    .gl-select ~ &,
    .gl-input_file-fake ~ &,
    .iti ~ & {
        top: 1px;
        width: auto;
        padding: 0 2px;
        border-radius: 0;
        max-height: 16px;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: calc(100% - 30px);
    }
}

// =================================
//    slick styleguide styles
// =================================

// slick slider with equal height slides
.gl_slick-equal-slides {
    .slick-track {
        display: flex !important;
    }

    .slick-slide {
        height: inherit !important;
    }
}

.gl_slick-dots-white {
    .slick-dots {
        li {
            background-color: $white;
        }
    }
}

.gl_slick-dots-border_black {
    .slick-dots {
        li {
            border: 1px solid $black1;
        }
    }
}

.gl_slick-arrow-circle {
    .slick-arrow {
        background-color: $white;
        border-radius: 50%;
    }
}

.gl_slick-arrow-outside {
    .slick-arrow {
        @include absolute-vertical-center;
    }

    .slick-prev {
        left: -40px;
    }

    .slick-next {
        right: -40px;
    }
}

.gl_slick-arrow-panel {
    display: flex;
    justify-content: center;

    .slick-arrow {
        margin: 0 5px;
    }

    &.gl_slick-arrow-panel-right {
        justify-content: flex-end;

        .slick-prev {
            margin: 0 5px 0 0;
        }

        .slick-next {
            margin: 0 0 0 5px;
        }
    }
}

// Rounded and hollowed vertical rod with dynamic height based on text rows
.gl_lines-container {
    position: relative;

    .gl_lines-text {
        text-align: left;
        margin: 0;
    }

    &::before {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        left: -26px; // 10px from Zeplin + 16px width
        width: 16px;
        height: 100%;
        border: 2px solid $black1;
        border-radius: 10px;
        box-sizing: border-box;

        @include media('>=desktopLarge') {
            left: -30px;
        }
    }
}

// snake shapes
.gl_snake-wrapper {
    position: absolute;
    z-index: -1;
    overflow: hidden;
}

// breadcrumbs
.gl_breadcrumbs-wrapper {
    position: relative;
    height: 0;
}

.gl_breadcrumbs {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 2;
    width: auto;
    height: 20px;
    font: 12px/20px $ReaderRegularPro;
    letter-spacing: 1px;
    padding: 1px 10px;
    background-color: $white;
    color: $black1;
    border-radius: 10px;
}

.gl_breadcrumbs-link {
    text-decoration: none;

    & + &,
    & + .gl_breadcrumbs-currentpage {
        &::before {
            display: inline-block;
            content: " - ";
        }
    }
}
