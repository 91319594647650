// foundation_second-block content asset with textimage.isml: snake svg styles
.foundation_second-block-snake-1 {
    top: 0;
    right: 0;

    @include media('<tablet') {
        width: 188px; // Zeplin values for the container
        height: 96px;
    }

    @include media('>=tablet', '<desktop') {
        width: 358px;
        height: 145px;
    }

    @include media('>=desktop', '<desktopLarge') { // not in Zeplin, eyeballed
        width: 358px;
        height: 96px;
    }

    @include media('>=desktopLarge') {
        width: 360px;
        height: 277px;
    }

    .svgsnake {
        position: relative;

        @include media('<tablet') {
            top: calc(-1 * (213px - 96px)); // SVG height - container height
            width: 197px; // Zeplin values for the SVG
            height: 213px;
        }

        @include media('>=tablet', '<desktopLarge') {
            top: calc(-1 * (407px - 145px));
            width: 376px;
            height: 407px;
        }

        @include media('>=desktop', '<desktopLarge') { // not in Zeplin, eyeballed
            top: calc(-1 * (407px - 96px));
        }

        @include media('>=desktopLarge') {
            top: calc(-1 * (407px - 277px));
            width: 376px;
            height: 407px;
        }
    }
}

// same snake shape as first one, but mirrored horizontally
.foundation_second-block-snake-2 {
    bottom: 0;
    left: 0;

    @include media('<tablet') {
        width: 191px;
        height: 41px;
    }

    @include media('>=tablet', '<desktopLarge') {
        width: 287px;
        height: 68px;
    }

    @include media('>=desktopLarge') {
        width: 368px;
        height: 85px;
    }

    .svgsnake {
        transform: rotate(-180deg); // mirror display
        position: relative;

        @include media('<tablet') {
            right: calc(196px - 191px); // SVG is right aligned, SVG width - container width
            width: 196px;
            height: 213px;
        }

        @include media('>=tablet', '<desktopLarge') {
            right: calc(376px - 287px);
            width: 376px;
            height: 407px;
        }

        @include media('>=desktopLarge') {
            right: calc(377px - 368px);
            width: 377px;
            height: 407px;
        }
    }
}

.foundation-third-block {
    @include media('>=desktopLarge') {
        margin: 60px 40px;
    }

    .text-only {
        color: $white;
        text-align: center;
        padding: 60px 30px;

        @include media('>=tablet') {
            padding: 100px;
        }

        @include media('>=desktopLarge') {
            padding: 127px 0;
            margin: 0 auto;
            max-width: 700px;
        }
    }

    .text-only-title + .text-only-description {
        margin-top: 30px;
    }
}

.foundation_fourth-block-snake {
    bottom: 0;
    left: 0;

    @include media('<tablet') {
        width: 101px; // Zeplin values for the container
        height: 247px;
    }

    @include media('>=tablet', '<desktop') {
        width: 258px;
        height: 504px;
    }

    @include media('>=desktop', '<desktopLarge') { // not in Zeplin, eyeballed
        width: 202px;
        height: 384px;
    }

    @include media('>=desktopLarge') {
        width: 232px;
        height: 474px;
    }

    .svgsnake {
        position: relative;

        @include media('<tablet') {
            left: calc(-1*(242px - 101px));
            width: 242px; // Zeplin values for the SVG
            height: 290px;
        }

        @include media('>=tablet', '<desktopLarge') { // not in Zeplin, eyeballed
            left: calc(-1 * (558px - 228px));
            width: 558px;
            height: 667px;
        }

        @include media('>=desktopLarge') {
            left: calc(-1 * (464px - 232px));
            width: 464px;
            height: 555px;
        }
    }
}

.foundation_fifth-block-snake {
    bottom: 0;
    right: 0;

    @include media('<tablet') {
        width: 101px; // Zeplin values for the container
        height: 223px;
    }

    @include media('>=tablet', '<desktop') {
        width: 266px;
        height: 485px;
    }

    @include media('>=desktop', '<desktopLarge') { // not in Zeplin, eyeballed
        width: 196px;
        height: 415px;
    }

    @include media('>=desktopLarge') {
        width: 220px;
        height: 429px;
    }

    .svgsnake {
        position: relative;

        @include media('<tablet') {
            width: 202px; // Zeplin values for the SVG
            height: 224px;
        }

        @include media('>=tablet', '<desktopLarge') {
            width: 477px;
            height: 529px;
        }

        @include media('>=desktopLarge') {
            width: 388px;
            height: 430px;
        }
    }
}

// Foundation page: crew block
.crew_carousel-wrapper {
    margin: 100px 0 80px;

    @include media('>=tablet') {
        margin: 100px 80px 60px;
    }

    @include media('>=desktop') {
        max-width: 840px;
        margin: 100px auto 50px;
    }
}

.crew_carousel-title {
    text-align: center;
    font: 28px / 36px $ReaderMediumPro;
    margin: 0 0 34px;

    @include media('>=tablet') {
        margin: 0 0 46px;
    }

    @include media('>=desktop') {
        margin: 0 0 44px;
    }
}

.crew_carousel-mobile {
    @include media('>=tablet') {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
    }
}

.crew_carousel-mobile:not(.slick-initialized) {
    @include media('<tablet') {
        margin: 0 30px;
    }
}

.crew_carousel-mobile.slick-initialized {
    @include media('<tablet') {
        margin: 0 0 0 30px;
    }

    .slick-slide {
        @include media('<tablet') {
            width: calc(100vw - 60px); // variableWidth = true
            margin: 0 8px;
        }
    }

    .slick-dots {
        @include media('<tablet') {
            justify-content: flex-start;
        }
    }
}

.crew_carousel-item {
    @include media('>=tablet') {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0 20px 40px;
    }

    @include media('>=desktop') {
        flex: 0 0 33.33%;
        max-width: 33.33%;
        padding: 0 20px 50px;
    }
}

.crew_carousel-text {
    margin: 15px 0 0;

    .crew_carousel-link {
        width: auto;
    }
}

.crew_carousel-header {
    font: 14px / 18px $ReaderMediumPro;
    margin: 0 0 12px;
}

.crew_carousel-desc {
    font: 12px / 20px $ReaderRegularPro;
    letter-spacing: 1px;
    margin: 0 0 18px;
}

// Crew detail page
.topsection-crewmember {
    .topsection-wrapper {
        margin: 40px 30px 60px;
    }

    .topsection-wave {
        @include media('>=desktop') {
            margin: 25px 0 30px;
        }
    }

    .topsection-description {
        font: 12px / 20px $ReaderRegularPro;
        letter-spacing: 1px;

        @include media('>=tablet') {
            max-width: 448px;
            margin: 0 auto;
        }

        @include media('>=desktop') {
            max-width: none;
        }
    }
}

.crew_detail-content {
    margin: 0 0 40px;

    @include media('>=desktop') {
        margin: 0 0 100px;
    }
}

.text_image-crewmember {
    &.text_image-wrapper {
        @include media('>=desktop') {
            align-items: flex-start;
        }

        @include media('>=desktopLarge') {
            align-items: stretch;
        }
    }

    .text_image-col {
        &.image_block {
            padding: 0 30px;

            @include media('>=tablet') {
                padding: 0 100px;
            }

            @include media('>=desktop') {
                padding: 0 0 0 40px;
            }
        }

        &.text_block {
            padding: 60px 30px;

            @include media('>=tablet') {
                padding: 60px 160px;
            }

            @include media('>=desktop') {
                padding: 20px 80px;
            }

            @include media('>=desktopLarge') {
                padding: 20px 140px;
            }
        }
    }

    .text_image-desc {
        margin: 0;
    }
}

.crew_detail-second-block {
    margin: 0 0 60px;

    @include media('>=desktop') {
        margin: 60px 0;
    }

    @include media('>=desktopLarge') {
        max-width: 1360px;
        margin: 60px auto;
    }

    .text-only {
        color: $white;
        text-align: center;
        padding: 100px 30px;

        @include media('>=tablet') {
            padding: 100px 160px;
        }

        @include media('>=desktop') {
            padding: 150px 0;
            margin: 0 auto;
            max-width: 700px;
        }
    }

    .text-only-title {
        font: 20px / 26px $ReaderMediumPro;
    }

    .text-only-description {
        font: 28px / 36px $ReaderMediumPro;
        letter-spacing: normal;
    }

    .text-only-title + .text-only-description {
        margin-top: 30px;
    }
}
