// Root News page
.news-header {
    margin: 40px 30px 95px;

    @include media('>=tablet') {
        margin: 40px auto 80px;
    }

    @include media('>=desktop') {
        margin: 75px auto;
    }
}

// Root News page: news snakes
.news-snake-1 {
    display: none;

    @include media('>=desktop') {
        display: block;
        top: calc(-1 * (290px - 230px)); // height from Zeplin SVG dimension - height from Zeplin container dimension
        right: calc(50% - 720px);
        width: 526px;
        height: 230px;
        overflow: visible;

        .svgsnake {
            width: 668px;
            height: 290px;
        }
    }
}

.news-snake-2 {
    display: none;

    @include media('>=desktop', '<desktopLarge') {
        display: block;
        top: 65px;
        left: calc(50% - 600px);
        transform: translateX(-144px); // 558px - 414px, no calc for translateX...
        width: 414px;
        height: 668px;
        overflow: visible;

        .svgsnake {
            width: 558px;
            height: 668px;
        }
    }

    @include media('>=desktopLarge') {
        display: block;
        top: 65px;
        left: calc(50% - 720px);
        transform: translateX(-144px); // 558px - 414px, no calc for translateX...
        width: 414px;
        height: 668px;
        overflow: visible;

        .svgsnake {
            width: 558px;
            height: 668px;
        }
    }
}

// News filters
.news-filters {
    display: flex;
    flex-flow: column nowrap;
    max-width: 250px;
    margin: 0 auto 60px;
    text-align: center;

    @include media('>=desktop') {
        flex-direction: row;
        justify-content: center;
        max-width: 1440px;
    }
}

.news-filter {
    position: relative;
    display: block;
    font: 12px / 20px $ReaderRegularPro;
    letter-spacing: 1px;
    margin: 0 0 10px;
    border-radius: 15px;
    border: solid 1px $black1;
    padding: 4px 10px;

    @include media('>=desktop') {
        padding: 4px 30px;
        margin: 0 5px;
    }

    &:last-child {
        margin: 0;

        @include media('>=desktop') {
            margin: 0 5px;
        }
    }

    .closesvg {
        @include absolute-vertical-center;

        display: none;
        right: 15px;
        width: 10px;
        height: 10px;

        .st0 {
            fill: $white;
        }

        @include media('>=desktop') {
            right: 10px;
        }
    }

    &.selected {
        background-color: $black1;
        color: $white;

        .closesvg {
            display: block;
        }
    }
}

// Highlighted article
.highlighted_article-wrapper {
    margin: 0 30px 40px;

    @include media('>=tablet') {
        max-width: 568px;
        margin: 0 auto 100px;
    }

    @include media('>=desktop') {
        max-width: 1160px;
    }
}

.highlighted_article-tile {
    background-color: $grey3;

    @include media('>=desktop') {
        display: flex;
        flex-flow: row nowrap;
        align-items: stretch;
        margin: 0 60px;
    }

    @include media('>=desktopLarge') {
        margin: 0;
    }
}

.highlighted_article-col {
    @include media('>=desktop') {
        flex: 0 0 50%;
        max-width: 50%;
    }
}

.highlighted_article-content {
    padding: 30px 40px;
    background-color: $grey3;

    @include media('>=tablet') {
        padding: 60px;
    }

    @include media('>=desktop') {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        padding: 30px 100px 30px 90px;
    }

    @include media('>=desktopLarge') {
        padding: 50px 130px 50px 120px;
    }
}

.highlighted_article-title-wrap {
    margin: 0 0 10px;
}

.highlighted_article-title.gl_lines-text {
    font: 20px / 26px $ReaderMediumPro;
}

.highlighted_article-text {
    .highlighted_article-link {
        width: auto;
        min-width: 140px;
    }
}

.highlighted_article-desc {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1px;
    margin: 0 0 20px;
}

// Highlighted article: news snakes
.highlighted_article-snake-1 {
    display: none;
    z-index: 1;

    @include media('>=tablet', '<desktop') {
        display: block;
        top: 0;
        left: 0;
        transform:rotate(180deg);
        width: 169px;
        height: 115px;
        overflow: visible;

        .svgsnake {
            width: 377px;
            height: 408px;
        }
    }

    @include media('>=desktop', '<desktopLarge') {
        display: block;
        top: 0;
        left: 0;
        transform:rotate(180deg);
        width: 125px;
        height: 285px;
        overflow: visible;

        .svgsnake {
            width: 377px;
            height: 408px;
        }
    }

    @include media('>=desktopLarge') {
        display: block;
        top: 0;
        left: 0;
        transform:rotate(180deg);
        width: 125px;
        height: 335px;
        overflow: visible;

        .svgsnake {
            width: 377px;
            height: 408px;
        }
    }
}

.highlighted_article-snake-2 {
    display: none;
    z-index: 1;

    @include media('>=tablet', '<desktop') {
        display: block;
        bottom: 0;
        right: 0;
        transform: translateX(338px) translateY(382px) rotate(180deg);
        width: 138px;
        height: 146px;
        overflow: visible;

        .svgsnake {
            width: 476px;
            height: 528px;
        }
    }

    @include media('>=desktop', '<desktopLarge') {
        display: block;
        bottom: 0;
        right: 0;
        transform: translateX(297px) translateY(371px) rotate(180deg);
        width: 179px;
        height: 157px;
        overflow: visible;

        .svgsnake {
            width: 476px;
            height: 528px;
        }
    }

    @include media('>=desktopLarge') {
        display: block;
        bottom: 0;
        right: 0;
        transform: translateX(297px) translateY(321px) rotate(180deg);
        width: 179px;
        height: 207px;
        overflow: visible;

        .svgsnake {
            width: 476px;
            height: 528px;
        }
    }
}

// Article list
.news-block {
    .news-loader {
        align-items: flex-start;
    }
}

.news-articles {
    @include media('>=tablet') {
        max-width: 668px;
        margin: 0 auto;
    }

    @include media('>=desktop') {
        max-width: 1380px;
    }

    .news-list-loader {
        align-items: flex-end;
        padding: 0 0 80px;
    }
}

.articles-list {
    @include media('>=tablet') {
        display: flex;
        flex-flow: row wrap;
    }

    @include media('>=desktop') {
        margin: 0 30px;
    }

    @include media('>=desktopLarge') {
        margin: 0;
    }

    .article-wrapper {
        @include media('>=tablet') {
            flex: 0 0 50%;
            max-width: 50%;
        }

        @include media('>=desktop') {
            flex: 0 0 33.33%;
            max-width: 33.33%;
        }
    }
}

// Article
.article-wrapper {
    padding: 0 30px 30px;

    @include media('>=tablet') {
        padding: 0 10px 60px;
    }
}

.article-content {
    padding: 30px 40px;
    background-color: $grey3;

    @include media('>=tablet') {
        padding: 30px 4px 30px 50px;
    }
}

.article-title-wrap {
    margin: 0 0 10px;
}

.article-title.gl_lines-text {
    font: 20px / 26px $ReaderMediumPro;
}

.article-text {
    .article-link {
        width: auto;
    }
}

.article-desc {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1px;
    margin: 0 0 20px;
}

.article-more_wrap {
    text-align: center;
}

.articles-more_btn {
    display: inline-block;
    min-width: 175px;
    font: 14px / 18px $ReaderRegularPro;
    letter-spacing: 1px;
    border-radius: 15px;
    border: solid 1px $black1;
    padding: 6px 10px;
    text-align: center;
    margin: 30px 30px 100px;

    @include media('>=tablet') {
        margin: 0 30px 100px;
    }
}

// Statements block
.statements-wrapper {
    text-align: center;
    background-color: $blue4;
    background-position: center center;
    background-size: cover;
}

.statements-text {
    color: $white;
    padding: 100px 30px;

    @include media('>=tablet') {
        padding: 100px 0;
        margin: 0 auto;
        max-width: 568px;
    }

    @include media('>=desktop') {
        max-width: 600px;
    }
}

.statements-title {
    font: 28px / 36px $ReaderMediumPro;
    margin: 0 0 20px;
}

.statements-description {
    font: 12px / 20px $ReaderRegularPro;
    letter-spacing: 1px;
    margin: 0 0 44px;

    @include media('>=tablet') {
        max-width: 448px;
        margin: 0 auto 24px;
    }

    @include media('>=desktop') {
        max-width: 560px;
        margin: 0 auto 44px;
    }
}

.statements-item {
    margin: 0 0 24px;

    &:last-child {
        margin: 0;
    }
}

.statements-download {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: 0 0 4px;
    border-bottom: 1px solid $white;
    color: $white;

    @include media('>=tablet') {
        padding: 0 0 2px;
    }
}

.statements-file {
    font: 14px / 18px $ReaderRegularPro;
    flex-grow: 1;
    text-align: left;
    padding: 0 25px 0 0;

    @include media('>=tablet') {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: flex-end;
        padding: 0 130px 0 0;
    }
}

.statements-file_title {
    @include media('>=tablet') {
        display: block;
        flex-grow: 1;
    }
}

.statements-file_info {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1px;

    @include media('>=tablet') {
        display: block;
        flex-shrink: 0;
    }
}

.statements-file_space {
    @include media('>=tablet') {
        display: block;
    }
}

.statements-icon {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 0 0 4px;
    flex-shrink: 0;

    @include media('>=tablet') {
        padding: 0 0 2px;
    }
}

// news article detail page
.newsdetail-wrapper {
    font: 12px / 20px $ReaderRegularPro;

    .topsection-wrapper {
        margin: 40px 30px 60px;

        @include media('>=tablet') {
            margin: 40px auto 60px;
        }
        @include media('>=desktop') {
            max-width: 1360px;
            margin: 75px auto 60px;
        }
    }

    .topsection-wave {
        margin: 20px 0 60px;
    }

    .topsection-description {
        display: none; // security
    }

    .text_image-wrapper {
        margin: 60px 30px 100px;

        @include media('>=tablet') {
            max-width: 568px;
            margin: 60px auto 100px;
        }

        @include media('>=desktop') {
            max-width: 1440px;
            margin: 100px auto;
            padding: 0 40px;
        }
    }

    .image_block {
        order: -1;

        @include media('>=desktop') {
            order: 0; // will work for imageOnLeft true and false
            flex: 0 0 48%;
            max-width: 48%;
        }

        @include media('>=desktopLarge') {
            flex: 0 0 660px;
            max-width: 50%;
        }
    }

    .text_image-title_wrap::before {
        content: none;
    }

    .text_block {
        text-align: left;
        padding: 60px 0 0;

        @include media('>=tablet') {
            padding: 60px 60px 0;
        }

        @include media('>=desktop') {
            padding: 20px 80px 20px 60px;
            flex-grow: 1;
            max-width: none;
        }

        @include media('>=desktopLarge') {
            padding: 20px 140px 20px 100px;
        }

        .text_image-title {
            font: 20px / 26px $ReaderMediumPro;
        }

        .text_image-desc {
            margin: 0;
        }

        .text_image-link {
            display: none; // security
        }
    }
}

.newsdetail-bannerwithdescription-wrapper {
    text-align: center;
    margin: 0 30px 60px;

    @include media('>=tablet') {
        max-width: 568px;
        margin: 0 auto 60px;
    }

    @include media('>=desktop') {
        max-width: 1440px;
        padding: 0 40px;
    }

    .newsdetail-banner {
        margin: 0 0 60px;
    }

    .newsdetail-description {
        font: 14px / 20px $ReaderRegularPro;
        letter-spacing: 1px;

        @include media('>=tablet') {
            max-width: 448px;
            margin: 0 auto;
        }

        @include media('>=desktop') {
            max-width: 700px;
        }
    }
}

.newsdetail-carousel-wrapper {
    margin: 60px 30px;

    @include media('>=tablet') {
        max-width: 568px;
        margin: 60px auto;
    }

    @include media('>=desktop') {
        max-width: 1440px;
        margin: 60px auto 100px;
        padding: 0 40px;
    }

    .vf-carousel-controls {
        @include media('>=desktop') {
            width: 48%;
        }

        @include media('>=desktopLarge') {
            width: 660px;
            max-width: 50%;
        }
    }

    .vf-carousel-img-wrap {
        @include media('>=desktop') {
            flex: 0 0 48%;
            max-width: 48%;
        }

        @include media('>=desktopLarge') {
            flex: 0 0 660px;
            max-width: 50%;
        }
    }

    .vf-carousel-text {
        padding: 60px 0 0;

        @include media('>=tablet') {
            padding: 60px 60px 0;
        }

        @include media('>=desktop') {
            padding: 20px 40px 20px 80px;
            flex-grow: 1;
        }

        @include media('>=desktopLarge') {
            padding: 20px 110px 20px 150px;
        }
    }

    .vf-carousel-title {
        font: 20px / 26px $ReaderMediumPro;
        margin: 0 0 24px;
    }

    .vf-carousel-desc {
        font: 20px / 26px $ReaderMediumPro;
    }

    .slick-arrow {
        @include media('<desktop') {
            position: absolute;
            top: 30px;
            z-index: 1;
            overflow: hidden;
        }

        &::before {
            @include media('<desktop') {
                margin: 15px 10px;
            }
        }
    }

    .slick-next {
        @include media('<desktop') {
            right: 10px;
        }
    }

    .slick-prev {
        @include media('<desktop') {
            left: 10px;
        }
    }
}

.newsdetail-video-wrapper {
    margin: 60px 30px;
}

.video-article-wrapper {
    @include media('>=tablet') {
        max-width: 568px;
        margin: 60px auto;
    }

    @include media('>=desktop') {
        max-width: 960px;
        margin: 100px auto;
    }

    .video-article-description {
        @include media('>=tablet') {
            max-width: 448px;
            margin: 60px auto 0;
        }

        @include media('>=desktop') {
            max-width: 700px;
        }
    }
}

.other-articles_list {
    margin: 100px 0 70px;

    @include media('>=tablet') {
        max-width: 668px;
        margin: 100px auto 70px;
    }

    @include media('>=desktop') {
        max-width: 1380px;
    }
}

.other-articles_title {
    font: 28px / 36px $ReaderMediumPro;
    text-align: center;
    margin: 0 30px 34px;

    @include media('>=tablet') {
        margin: 0 0 34px;
    }
}
