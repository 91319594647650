.footer-social-icon {
    display: block;

    & > svg {
        fill: $white;
        width: 40px;

        path {
            fill: $black1;
        }

        circle {
            &.st1 {
                fill: $black1;
            }

            &.st2 {
                stroke: $black1;
            }
        }
    }
}

.donation-social-icon {
    display: block;

    & > svg {
        fill: $white;
        width: 40px;

        path {
            fill: $blue4;
        }

        circle {
            &.st1 {
                fill: $blue4;
            }

            &.st2 {
                stroke: $blue4;
            }
        }
    }
}

.svgfoundationlogo {
    display: block;
    width: 44px;
    height: 40px;

    .st0 {
        fill: $blue1;
    }

    .st1 {
        fill: $blue2;
    }

    .st2 {
        fill: $blue3;
    }
}

.svgfoundationlogo-white {
    .st0 {
        fill: $white;
        opacity: 0.9;
    }

    .st1 {
        fill: $white;
        opacity: 0.8;
    }

    .st2 {
        fill: $white;
    }
}

.svgburger {
    width: 20px;
    height: 20px;

    .st0 {
        fill: $black1;
    }
}

.svgseaweed {
    width: 140px;
    height: 130px;

    .st0 {
        fill: $white;
    }
}

.svgfoundationturtle {
    width: 150px;
    height: 150px;

    .st0 {
        fill: $white;
    }
}

.svgtshirt {
    width: 150px;
    height: 128px;

    .st0 {
        fill: $white;
    }
}

.closesvg {
    width: 20px;
    height: 19px;

    .st0 {
        fill: $black1;
    }
}

.svgchevron {
    width: 10px;

    .st0 {
        fill: $black1;
    }
}

.chevron-down-container {
    width: 18px;
    text-align: center;

    .svgchevron {
        transform: rotate(90deg);
    }
}

.svgsinglewave {
    width: 60px;
    height: 15px;

    .st0 {
        fill: $white;
    }
}

.gl_svgsinglewave-black {
    .svgsinglewave {
        .st0 {
            fill: $black1;
        }
    }
}

// Snake-like shapes
.svgsnake {
    display: block;

    .st0 {
        fill: $blue5;
    }
}

// Snake-like shapes as absolute bg
.gl_snake-parent {
    position: relative;
    overflow: hidden;

    @include media('>=desktop') {
        .gl_snake-grandparent & {
            position: static;
            overflow: auto;
        }
    }
}

.gl_snake-grandparent {
    @include media('>=desktop') {
        position: relative;
        overflow: hidden;
    }
}

.gl_snake-wrapper {
    position: absolute;
}

.svgloader {
    width: 70px;
    height: 70px;

    .st0 {
        fill: $loader-blue1;
    }

    .st1 {
        fill: $loader-blue2;
    }

    .st2 {
        fill: $loader-blue3;
    }

    .st3 {
        fill: $loader-blue4;
    }

    .st4 {
        fill: $loader-blue5;
    }

    .st5 {
        fill: $loader-blue6;
    }

    .st6 {
        fill: $white;
    }
}

// program icons (on program pages)
.gl_program-icon-wrapper {
    position: absolute;
}

.svgdownload {
    display: block;
    width: 20px;
    height: 20px;

    .st0 {
        fill: $black1;
    }
}

.gl_svgdownload-white {
    .svgdownload {
        .st0 {
            fill: $white;
        }
    }
}

.svglock {
    width: 10px;
    height: 14px;

    .st0 {
        fill: $white;
    }
}
