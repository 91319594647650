// General content page styles
.contentpage-wrapper {
    font: 12px / 20px $ReaderRegularPro;
    margin: 40px 30px 100px;

    @include media('>=tablet') {
        margin: 40px 60px 100px;
    }

    @include media('>=desktop') {
        max-width: 900px;
        margin: 75px auto 100px;
    }

    @include media('>=desktopLarge') {
        max-width: 960px;
    }

    dl,
    dd {
        margin: 0;
    }

    dt,
    li {
        text-indent: 4px;

        &::before {
            content: ".";
            display: inline-block;
            font-weight: bold;
            position: relative;
            left: -8px;
            bottom: 3px;
        }
    }
}

.contentpage-header {
    text-align: center;
}

.contentpage-title {
    margin: 0 0 20px;
}

.contentpage-wave {
    height: 15px;
    margin: 0 0 40px;

    @include media('>=desktop') {
        margin: 0 0 60px;
    }
}

// Cookies page: cookies snakes
.cookies-snake-1 {
    @include media('<tablet') {
        top: 0;
        right: 0;
        width: 113px;
        height: 372px;

        .svgsnake {
            position: relative;
            top: calc(-1 * (404px - 372px)); // height from Zeplin SVG dimension - height from Zeplin container dimension
            width: 338px;
            height: 404px;
        }
    }

    @include media('>=tablet', '<desktopLarge') {
        top: 0;
        right: 0;
        width: 206px;
        height: 667px;

        .svgsnake {
            position: relative;
            width: 558px;
            height: 667px;
        }
    }

    @include media('>=desktopLarge') {
        top: 0;
        right: calc(50% - 720px);
        width: 311px;
        height: 667px;
        overflow: visible;

        .svgsnake {
            width: 559px;
            height: 667px;
        }
    }
}

.cookies-snake-2 {
    @include media('<tablet') {
        top: 25%;
        left: 0;
        width: 22px;
        height: 320px;

        .svgsnake {
            transform: translateX(-266px); // 288px - 22px, no calc for translateX...
            width: 288px;
            height: 320px;
        }
    }

    @include media('>=tablet', '<desktop') {
        top: 121px;
        left: 0;
        width: 73px;
        height: 528px;

        .svgsnake {
            transform: translateX(-403px); // 476px - 73px, no calc for translateX...
            width: 476px;
            height: 528px;
        }
    }

    @include media('>=desktop', '<desktopLarge') {
        top: 200px;
        left: 0;
        width: 73px;
        height: 528px;

        .svgsnake {
            transform: translateX(-403px); // 476px - 73px, no calc for translateX...
            width: 476px;
            height: 528px;
        }
    }

    @include media('>=desktopLarge') {
        top: 495px;
        left: calc(50% - 720px);
        width: 134px;
        height: 528px;
        overflow: visible;

        .svgsnake {
            transform: translateX(-342px); // 476px - 134px, no calc for translateX...
            width: 476px;
            height: 528px;
        }
    }
}

.cookies-snake-3 {
    @include media('<tablet') {
        bottom: 0;
        right: 0;
        width: 232px;
        height: 51px;

        .svgsnake {
            width: 404px;
            height: 176px;
        }
    }

    @include media('>=tablet', '<desktop') {
        bottom: 0;
        right: 0;
        width: 374px;
        height: 70px;

        .svgsnake {
            width: 668px;
            height: 290px;
        }
    }

    @include media('>=desktop') {
        display: none;
    }
}

// Cookies page
.cookies-h2 {
    font: 14px / 18px $ReaderMediumPro;
    padding: 0 0 6px;
    border-bottom: 1px solid $black;
    margin: 0 0 20px;

    @include media('>=desktop') {
        margin: 0 0 30px;
    }
}

.cookies-text {
    font: 12px / 20px $ReaderRegularPro;
    letter-spacing: 1px;
    margin: 0 0 40px;

    @include media('>=desktop') {
        margin: 0 0 60px;
    }
}

.cookies-table {
    th, td {
        @include media('>=desktop') {
            width: 25%;
        }
    }

    th {
        font: 14px / 18px $ReaderMediumPro;
    }

    td {
        font: 12px / 20px $ReaderRegularPro;
        letter-spacing: 1px;
    }
}

.cookies-mobile-table {
    margin: 0 0 40px;

    &:last-child {
        margin: 0;
    }

    tr {
        display: flex;
        flex-flow: column nowrap;
    }
}

// Terms and Conditions of Use page: terms snakes
.terms-snake-1 {
    @include media('<tablet') {
        top: 0;
        right: 0;
        width: 113px;
        height: 372px;

        .svgsnake {
            position: relative;
            top: calc(-1 * (404px - 372px)); // height from Zeplin SVG dimension - height from Zeplin container dimension
            width: 338px;
            height: 404px;
        }
    }

    @include media('>=tablet', '<desktopLarge') {
        top: 0;
        right: 0;
        width: 206px;
        height: 667px;

        .svgsnake {
            position: relative;
            width: 558px;
            height: 667px;
        }
    }

    @include media('>=desktopLarge') {
        top: 0;
        right: calc(50% - 720px);
        width: 311px;
        height: 667px;
        overflow: visible;

        .svgsnake {
            width: 559px;
            height: 667px;
        }
    }
}

.terms-snake-2 {
    @include media('<tablet') {
        top: 25%;
        left: 0;
        width: 22px;
        height: 320px;

        .svgsnake {
            transform: translateX(-266px); // 288px - 22px, no calc for translateX...
            width: 288px;
            height: 320px;
        }
    }

    @include media('>=tablet', '<desktop') {
        top: 121px;
        left: 0;
        width: 73px;
        height: 528px;

        .svgsnake {
            transform: translateX(-403px); // 476px - 73px, no calc for translateX...
            width: 476px;
            height: 528px;
        }
    }

    @include media('>=desktop', '<desktopLarge') {
        top: 200px;
        left: 0;
        width: 73px;
        height: 528px;

        .svgsnake {
            transform: translateX(-403px); // 476px - 73px, no calc for translateX...
            width: 476px;
            height: 528px;
        }
    }

    @include media('>=desktopLarge') {
        top: 495px;
        left: calc(50% - 720px);
        width: 134px;
        height: 528px;
        overflow: visible;

        .svgsnake {
            transform: translateX(-342px); // 476px - 134px, no calc for translateX...
            width: 476px;
            height: 528px;
        }
    }
}

.terms-snake-3 {
    @include media('<tablet') {
        bottom: 0;
        right: 0;
        width: 232px;
        height: 51px;

        .svgsnake {
            width: 404px;
            height: 176px;
        }
    }

    @include media('>=tablet', '<desktop') {
        bottom: 0;
        right: 0;
        width: 374px;
        height: 70px;

        .svgsnake {
            width: 668px;
            height: 290px;
        }
    }

    @include media('>=desktop') {
        display: none;
    }
}

// Legal page: legal snakes
.legal-snake-1 {
    @include media('<tablet') {
        top: 0;
        right: 0;
        width: 113px;
        height: 372px;

        .svgsnake {
            position: relative;
            top: calc(-1 * (404px - 372px)); // height from Zeplin SVG dimension - height from Zeplin container dimension
            width: 338px;
            height: 404px;
        }
    }

    @include media('>=tablet', '<desktopLarge') {
        top: 0;
        right: 0;
        width: 206px;
        height: 667px;

        .svgsnake {
            position: relative;
            width: 558px;
            height: 667px;
        }
    }

    @include media('>=desktopLarge') {
        top: 0;
        right: calc(50% - 720px);
        width: 311px;
        height: 667px;
        overflow: visible;

        .svgsnake {
            width: 559px;
            height: 667px;
        }
    }
}

.legal-snake-2 {
    @include media('<tablet') {
        top: 25%;
        left: 0;
        width: 22px;
        height: 320px;

        .svgsnake {
            transform: translateX(-266px); // 288px - 22px, no calc for translateX...
            width: 288px;
            height: 320px;
        }
    }

    @include media('>=tablet', '<desktop') {
        top: 121px;
        left: 0;
        width: 73px;
        height: 528px;

        .svgsnake {
            transform: translateX(-403px); // 476px - 73px, no calc for translateX...
            width: 476px;
            height: 528px;
        }
    }

    @include media('>=desktop', '<desktopLarge') {
        top: 200px;
        left: 0;
        width: 73px;
        height: 528px;

        .svgsnake {
            transform: translateX(-403px); // 476px - 73px, no calc for translateX...
            width: 476px;
            height: 528px;
        }
    }

    @include media('>=desktopLarge') {
        @include absolute-vertical-center;
        left: calc(50% - 720px);
        width: 134px;
        height: 528px;
        overflow: visible;

        .svgsnake {
            transform: translateX(-342px); // 476px - 134px, no calc for translateX...
            width: 476px;
            height: 528px;
        }
    }
}

.legal-snake-3 {
    @include media('<tablet') {
        bottom: 0;
        right: 0;
        width: 232px;
        height: 51px;

        .svgsnake {
            width: 404px;
            height: 176px;
        }
    }

    @include media('>=tablet', '<desktop') {
        bottom: 0;
        right: 0;
        width: 374px;
        height: 70px;

        .svgsnake {
            width: 668px;
            height: 290px;
        }
    }

    @include media('>=desktop') {
        display: none;
    }
}

// FAQ list: Terms and Conditions of Use page & Legal page
.faq-info-title {
    position: relative;
    // line-height: 20px is important for ouline
    font: 14px / 20px $ReaderMediumPro;
    padding: 0 35px 4px 0;
    border-bottom: 1px solid $black;
    margin: 0 0 40px;
    cursor: pointer;

    @include media('>=desktop') {
        margin: 0 0 30px;
    }

    &.active {
        margin: 0 0 20px;
        overflow: hidden;

        @include media('>=desktop') {
            margin: 0 0 30px;
        }
    }

    &::before {
        display: block;
        content: '';
        position: absolute;
        top: 2px;
        right: 3px;
        width: 9px;
        height: 9px;
        border: 1px solid $black1;
        border-width: 1px 0 0 1px;
        transform: rotate(225deg);
    }

    &.active::before {
        top: 8px;
        transform: rotate(45deg);
    }
}

.faq-info-desc {
    display: none;
    font: 12px / 20px $ReaderRegularPro;
    letter-spacing: 1px;
    margin: 0 0 40px;

    @include media('>=desktop') {
        margin: 0 0 60px;
    }

    &.active {
        display: block;
    }
}

// Contactus page: contact-us snakes
.contactus-snake-1 {
    @include media('<tablet') {
        top: 0;
        right: 0;
        width: 113px;
        height: 385px;

        .svgsnake {
            position: relative;
            top: calc(-1 * (404px - 385px)); // height from Zeplin SVG dimension - height from Zeplin container dimension
            width: 338px;
            height: 404px;
        }
    }

    @include media('>=tablet', '<desktopLarge') {
        top: 0;
        right: 0;
        width: 206px;
        height: 667px;

        .svgsnake {
            position: relative;
            width: 558px;
            height: 667px;
        }
    }

    @include media('>=desktopLarge') {
        top: 0;
        right: calc(50% - 720px);
        width: 339px;
        height: 667px;
        overflow: visible;

        .svgsnake {
            width: 559px;
            height: 667px;
        }
    }
}

.contactus-snake-2 {
    @include media('<tablet') {
        @include absolute-vertical-center;
        left: 0;
        width: 50px;
        height: 320px;

        .svgsnake {
            transform: translateX(-238px); // 288px - 50px, no calc for translateX...
            width: 288px;
            height: 320px;
        }
    }

    @include media('>=tablet', '<desktopLarge') {
        top: 121px;
        left: 0;
        width: 73px;
        height: 528px;

        .svgsnake {
            transform: translateX(-403px); // 476px - 73px, no calc for translateX...
            width: 476px;
            height: 528px;
        }
    }

    @include media('>=desktopLarge') {
        top: 281px;
        left: calc(50% - 720px);
        width: 161px;
        height: 528px;
        overflow: visible;

        .svgsnake {
            transform: translateX(-315px); // 476px - 161px, no calc for translateX...
            width: 476px;
            height: 528px;
        }
    }
}

.contactus-snake-3 {
    @include media('<tablet') {
        bottom: 0;
        right: 0;
        width: 232px;
        height: 51px;

        .svgsnake {
            width: 404px;
            height: 176px;
        }
    }

    @include media('>=tablet', '<desktopLarge') {
        bottom: 0;
        right: 0;
        width: 374px;
        height: 70px;

        .svgsnake {
            width: 668px;
            height: 290px;
        }
    }

    @include media('>=desktopLarge') {
        bottom: 0;
        right: calc(50% - 720px);
        width: 393px;
        height: 130px;
        overflow: visible;

        .svgsnake {
            width: 668px;
            height: 290px;
        }
    }
}

// Contactus page
.contactus-wrapper {
    margin: 40px 30px 100px;

    @include media('>=tablet') {
        max-width: 648px;
        margin: 40px auto 85px;
    }

    @include media('>=desktop') {
        max-width: 840px;
        margin: 75px auto;
    }
}

.contactus-text {
    text-align: center;

    @include media('>=tablet') {
        max-width: 448px;
        margin: 0 auto;
    }

    @include media('>=desktop') {
        max-width: 500px;
    }
}

.contactus-title {
    margin: 0;
}

.contactus-wave {
    height: 15px;
    margin: 20px 0 30px;
}

.contactus-desc {
    font: 12px / 20px $ReaderRegularPro;
    letter-spacing: 1px;
    margin: 0 0 40px;

    @include media('>=desktop') {
        margin: 0 0 60px;
    }
}

.contactus-form {
    .contactus-form_loader {
        .gl_loader {
            align-items: flex-end;
            padding: 0 0 140px;

            @include media('>=tablet') {
                padding: 0 0 60px;
            }
        }

        &.visible {
            display: block;
        }
    }
}

.contactus-required-info {
    font: 12px / 20px $ReaderMediumPro;
    letter-spacing: 1px;
    color: $black;
    text-align: right;
    margin: 0 0 28px;
}

.contactus-group-1 {
    padding: 0 0 30px;
    border-bottom: 1px solid $grey4;

    @include media('>=tablet') {
        display: flex;
        flex-flow: row wrap;
    }

    .gl_form-group {
        @include media('>=tablet') {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }

    .gl_form-group:nth-child(odd) {
        @include media('>=tablet') {
            padding: 0 10px 28px 0;
        }

        &.gl_has-error {
            @include media('>=tablet') {
                padding: 0 10px 8px 0;
            }
        }
    }

    .gl_form-group:nth-child(even) {
        @include media('>=tablet') {
            padding: 0 0 28px 10px;
        }

        &.gl_has-error {
            @include media('>=tablet') {
                padding: 0 0 8px 10px;
            }
        }
    }
}

.contactus-group-2 {
    padding: 40px 0;
    border-bottom: 1px solid $grey4;

    @include media('>=tablet') {
        display: flex;
        flex-flow: row wrap;
    }
}

.contactus-youare_field {
    padding-bottom: 38px;

    &.gl_has-error {
        padding-bottom: 16px;
    }

    @include media('>=tablet') {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0 10px 40px 0;
    }

    @include media('>=desktop') {
        padding: 0 10px 30px 0;
    }
}

.contactus-reason_field {
    @include media('>=tablet') {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 30px 0 40px 10px;
    }

    @include media('>=desktop') {
        padding: 30px 0 30px 10px;
    }
}

.contactus-comment_field {
    padding: 0;

    @include media('>=tablet') {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

.contactus-submit {
    padding: 45px 0 0;

    @include media('>=tablet') {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        padding: 32px 0 0;
    }

    @include media('>=desktop') {
        padding: 37px 0 0;
    }

    .grecaptcha-badge {
        margin: 0 auto 47px;

        @include media('>=tablet') {
            margin: 0;
        }
    }

    .contactus-btn {
        @include media('>=tablet') {
            width: 135px;
        }

        @include media('>=desktop') {
            width: 295px;
        }
    }
}

// Contactus confirmation page: contact-us-confirmation snakes
.contactus-confirmation-snake-1 {
    @include media('<tablet') {
        top: 0;
        right: 0;
        width: 113px;
        height: 385px;

        .svgsnake {
            position: relative;
            top: calc(-1 * (404px - 385px)); // height from Zeplin SVG dimension - height from Zeplin container dimension
            width: 338px;
            height: 404px;
        }
    }

    @include media('>=tablet', '<desktopLarge') {
        top: 0;
        right: 0;
        width: 206px;
        height: 667px;

        .svgsnake {
            position: relative;
            width: 558px;
            height: 667px;
        }
    }

    @include media('>=desktopLarge') {
        top: 0;
        right: calc(50% - 720px);
        width: 200px;
        height: 667px;
        overflow: visible;

        .svgsnake {
            width: 559px;
            height: 667px;
        }
    }
}

.contactus-confirmation-snake-2 {
    @include media('<tablet') {
        @include absolute-vertical-center;
        left: 0;
        width: 50px;
        height: 320px;

        .svgsnake {
            transform: translateX(-238px); // 288px - 50px, no calc for translateX...
            width: 288px;
            height: 320px;
        }
    }

    @include media('>=tablet', '<desktopLarge') {
        top: 121px;
        left: 0;
        width: 73px;
        height: 528px;

        .svgsnake {
            transform: translateX(-403px); // 476px - 73px, no calc for translateX...
            width: 476px;
            height: 528px;
        }
    }

    @include media('>=desktopLarge') {
        @include absolute-vertical-center;
        left: calc(50% - 720px);
        width: 140px;
        height: 528px;
        overflow: visible;

        .svgsnake {
            transform: translateX(-336px); // 476px - 140px, no calc for translateX...
            width: 476px;
            height: 528px;
        }
    }
}

.contactus-confirmation-snake-3 {
    @include media('<tablet') {
        bottom: 0;
        right: 0;
        width: 232px;
        height: 51px;

        .svgsnake {
            width: 404px;
            height: 176px;
        }
    }

    @include media('>=tablet') {
        display: none;
    }
}

// Contactus page: confirmation page
.contactus-confirmation-wrapper {
    margin: 40px 30px 100px;

    @include media('>=tablet') {
        max-width: 448px;
        margin: 40px auto 85px;
    }

    @include media('>=desktopLarge') {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        max-width: 1160px;
        margin: 150px auto;
    }
}

.contactus-confirmation-block-1 {
    text-align: center;
    margin: 0 0 50px;

    @include media('>=tablet') {
        max-width: 448px;
        margin: 0 auto 50px;
    }

    @include media('>=desktopLarge') {
        max-width: none;
        margin: 0;
        text-align: left;
        padding: 0 280px 0 0;
        flex-grow: 1;
    }
}

.contactus-confirmation-desc {
    font: 12px / 20px $ReaderRegularPro;
    letter-spacing: 1px;
    margin: 0 0 40px;

    @include media('>=desktopLarge') {
        margin: 0;
    }
}

.contactus-confirmation-block-2 {
    border: 1px solid $blue4;
    border-radius: 20px;
    padding: 60px 20px;
    text-align: center;

    @include media('>=tablet') {
        padding: 75px 45px;
    }

    @include media('>=desktopLarge') {
        width: 440px;
        padding: 75px 40px;
    }
}

.contactus-confirmation-desc-1 {
    font: 20px / 26px $ReaderMediumPro;
    margin: 0 0 20px;
}

.contactus-confirmation-desc-2 {
    font: 12px / 18px $ReaderRegularPro;
    letter-spacing: 1px;
}
